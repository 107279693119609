import React from "react"
import {connect} from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {getMerchant, setMerchantUser, logOut, setError} from "../store/actions/merchantActions";
import {appTransition} from "../utils/transitions"
import {stateFromProps} from "../utils/state"

import styles from "./AccountSettingsPage.module.scss";
import NavBar from "../components/NavBar";
import building from "../assets/img/building.svg";
import user from "../assets/img/user.svg";
import keyImg from "../assets/img/key.svg";
import user_color from "../assets/img/user_color.svg";
import {Option} from "../components/Option";
import {formatUserName} from "../utils/format";
import {isAdmin, verifyWisetackToken} from "../utils/auth";
import {USER_ROLE_DISPLAY_MAPPING} from "../utils/format"

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user"
]

const pageName = "Account Settings Page"
const logProps = {page: pageName}

class AccountSettingsPage extends React.Component {
    state = {
        errorMessage: "",
        userChanged: false,
        userId: null
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId
        if (this.props.user) {
            logProps.userId = this.props.user.userId
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user && this.props.user && prevProps.user.merchantId !== this.props.user.merchantId) {
            // TODO user changed
        }
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        if (e.alias !== 'account_settings') {
            appTransition(e, this.props)
        }
    }

    handleOnOptionClick = (e) => {
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        this.props.history.goBack();
    }

    render() {

        const settingOptions = [
            {
                imgSrc: building,
                imgAlt: "building.svg",
                label: "Merchant information",
                alias: "business"
            },
            {
                imgSrc: user,
                imgAlt: "user.svg",
                label: "User information",
                alias: "user"
            }
        ];

        const isAdminRole = isAdmin(this.props.user)

        if (isAdminRole) {
            settingOptions.push(
                {
                    imgSrc: keyImg,
                    imgAlt: "key.svg",
                    label: "User management",
                    alias: "user_management"
                }
            )
        }

        const options = settingOptions.map(option => {
            const alias = option.alias;
                return (
                    <Option key={option.label}
                            onClick={() => this.handleOnOptionClick({alias})}>
                        <div className={styles.label}>
                            <img src={option.imgSrc} alt={option.imgAlt}/>
                            {option.label}
                        </div>
                    </Option>
                )
            }
        )

        const userName = formatUserName(this.props.user)
        const userRoleDisplay = this.props.user ? USER_ROLE_DISPLAY_MAPPING[this.props.user.userRole] : ''

        return (
            <Container>
                <NavBar title={"Account settings"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <LoaderWithMessage loading={this.props.isLoading}/>
                {!this.props.isLoading &&
                <div className={styles.user}>
                    <span>
                        <img src={user_color} alt={"user_color.svg"}/>
                    </span>
                    {userName}
                    <div className={styles.role}>{userRoleDisplay}</div>
                </div>
                }
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                {options}
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        getMerchant,
        setMerchantUser,
        logOut,
        setError
    }
)(AccountSettingsPage);
