import classNames from "classnames";
import styles from "./Button.module.scss";
import React from "react";

function Button({children, customClasses, disabled, onClick}) {

    const btnClasses = [
        {
            btn: true,
            "btn-block": true,
            [styles.wisetack_button]: true,
            "btn-disabled": disabled
        }
    ]

    if (customClasses){
        btnClasses.push(customClasses);
    }

    const classesAsString = classNames(
        btnClasses
    );

    return (
        <button className={classesAsString} onClick={() => onClick()}>
            {children}
        </button>
    )
}

export default Button;
