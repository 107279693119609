import React, {useEffect} from 'react';
import classNames from "classnames";
import {connect} from "react-redux";
import {useHistory} from "react-router";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image from "@wisetack/assets/img/error.svg";

import styles from "./LinkBankModePage.module.scss";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

const pageName = "Link Bank Trouble Page"

function LinkBankModePage() {
    const history = useHistory();

    useEffect(() => {
        logAmplitudeEvent(pageName, {});
    }, []);

    const handleContinueButtonClick = () => {
        logAmplitudeEvent( pageName, {event: 'Pressed Enter Manually Button'});
        history.push('/link_bank_manual');
    }

    const handleReturnButtonClick = () => {
        logAmplitudeEvent( pageName, {event: 'Pressed Come Back Later Button'});
        history.push('/business');
    }

    const ContinueButton = () => {
        return (
            <button className={classNames("btn", "btn-block", styles.continueButton)} onClick={handleContinueButtonClick}>
                ENTER MANUALLY
            </button>
        )
    }

    const ReturnButton = () => {
        return (
            <button className={classNames("btn", "btn-block", styles.returnButton)} onClick={handleReturnButtonClick}>
                NO, COME BACK LATER
            </button>
        )
    }

    return <Container>
        <PageHeader  progress="80%">
            <div>
                Having trouble?
            </div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="error-logo"/>
        <div className={styles.message}>
            Let’s try this another way.
            Continue to manually enter your bank&nbsp;information.
        </div>
        <ContinueButton/>
        <ReturnButton/>
    </Container>
}

export default connect(null)(
    LinkBankModePage
);


