import React from "react"
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { setMerchantUser } from "../store/actions/merchantActions";
import { stateFromProps } from "../utils/state"
import styles from "./AccountsPage.module.scss";
import {Option} from "../components/Option";


const stateNames = [
    "isLoading",
    "errorMessage",
    "user",
    "users"
]

const pageName = "Select Account Page"
const logProps = {page: pageName}

class AccountsPage extends React.Component {
    state = {
        errorMessage: "",
        userSelected: false
    }

    componentDidMount() {
        window.scrollTo(0, 0)
        logAmplitudeEvent(pageName, logProps)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.users) {
            nextProps.history.push("/");
            return null;
        }
        if (nextProps.user && prevState.userSelected) {
            nextProps.history.push("/transaction_menu");
            return null;
        }
        return stateFromProps(nextProps, prevState, logProps);
    }

    handleAccountClick = (user) => {
        logAmplitudeEvent("User account selected", {
            ...logProps,
            userId: user.userId,
            merchantId: user.merchantId
        })
        this.setState({userSelected: true})
        this.props.setMerchantUser(user);
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `TransactionsPage state/props`);
        const HeaderContent = () => {
            return <PageHeader progress="">
                <div>Select account</div>
                <div></div>
            </PageHeader>
        }

        const accountList = this.props.users && this.props.users.map(user => {
            return (
                <Option onClick = {() => this.handleAccountClick(user)}
                        key = {user.userId} >
                    <div className={styles.merchant}>{user.merchantName}</div>
                </Option>
            )
        })

        return (
            <Container>
                <HeaderContent />
                {
                    !this.props.isLoading &&
                    <>
                        <Error pageName={pageName}>{this.state.errorMessage}</Error>
                        <div className={styles.accounts}>
                            {accountList}
                        </div>
                    </>
                }
                <LoaderWithMessage loading={this.props.isLoading} />
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { setMerchantUser }
)(AccountsPage);



