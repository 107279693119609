import React from "react";

import styles from "./Prequal.module.scss";
import {
    formatBorrowerName,
    formatDate,
    formatPrequalAmount,
    formatPrequalBadgeStyle,
    formatPrequalStatusInfo
} from "../utils/format";

const Prequal = ({item, onClick}) => {
    const displayDate = formatDate(item.createdAt);
    const [badgeText] = formatPrequalStatusInfo(item.status);
    const name = formatBorrowerName(item.customerInformation);
    const amount = formatPrequalAmount(item);
    const badgeStyle = formatPrequalBadgeStyle(item.status, styles);

    return (
        <div className={styles.prequal} onClick={() => onClick(item)}>
            <table>
                <tbody>
                <tr>
                    <td className={styles.name}>{name}</td>
                    <td className={styles.amount}>{amount}</td>
                </tr>
                <tr>
                    <td className={styles.status}>
                        <span className={badgeStyle}>{badgeText}</span>
                    </td>
                    <td className={styles.date}>{displayDate}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Prequal;