import React from "react"
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import PaymentCalculator from "@wisetack/shared-ui/components/PaymentCalculator";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { 
    getMerchant, 
    setMerchantUser, 
    logOut,
    setError 
} from "../store/actions/merchantActions";
import { appTransition } from "../utils/transitions"
import { stateFromProps } from "../utils/state"

import NavBar from "../components/NavBar";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user"
]

const pageName = "Payment Calculator Page"
const logProps = {page: pageName}

class PaymentCalculatorPage extends React.Component {
    state = {
        errorMessage: "",
        userChanged: false,
        userId: null
    }

    componentDidMount() {
        if (!sessionStorage.getItem("wisetack:ba:token")) {
            Log.error('Token not found.')
            this.props.history.push("/");
            return;
        }
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId
        logAmplitudeEvent(pageName, logProps);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user && this.props.user && prevProps.user.merchantId !== this.props.user.merchantId) {
            // TODO user changed
        }
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias 
        });
        this.props.setError(null)
        if (e.alias !== 'calculator') {
            appTransition(e, this.props)
        }
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `TransactionsPage state/props`);

        const HeaderContent = () => {
            return (
                <>
                    <NavBar title={"Payment calculator"}
                            users={this.state.users}
                            onMenuItemClick={this.handleMenuItemClick}
                    />
                </>
            )
        }

        return (
            <Container>
                <HeaderContent />
                <PaymentCalculator />
                <LoaderWithMessage loading={this.props.isLoading} />
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { 
        getMerchant, 
        setMerchantUser, 
        logOut,
        setError 
    }
)(PaymentCalculatorPage);
