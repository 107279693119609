import React from "react";
import classNames from "classnames";

import styles from "./FilterApplyButton.module.scss";

const FilterApplyButton = ({onClick, filterSelections}) => {

    const btnEnabled = !!onClick

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        [styles.buttonDisabled]: !btnEnabled,
        [styles.buttonEnabled]: btnEnabled
    });

    let caption = "APPLY"
    if (filterSelections > 0) {
        caption = caption + ` (${filterSelections})`
    }

    return (
        <div className="row">
            <div className="col">
                <button className={btnClasses} onClick={onClick}>
                    {caption}
                </button>
            </div>
        </div>
    )
}

export default FilterApplyButton;
