import React from "react";
import { connect } from "react-redux";
import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import styles from "./LinkBankSuccessPage.module.scss";
import image from "@wisetack/assets/img/piggy-bank.svg";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import LearnMoreModal from "@wisetack/shared-ui/components/LearnMoreModal";
import Log from "@wisetack/shared-ui/utils/Log";
import Buttons from "@wisetack/shared-ui/components/Buttons";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import { getMerchant, logOut, setError, updateMerchant } from "../store/actions/merchantActions";

const pageName = "Link Bank Success"

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user"
]

class LinkBankSuccessPage extends React.Component {
    isAccountDataProvided = (data) => {
        if (!data || Object.keys(data).length < 6) {
            return false;
        }
        if (!data.accountNumber || !data.accountNumberCopy || data.accountNumber !== data.accountNumberCopy) {
            return false;
        }
        return data.accountName && data.bankName && data.subtype && data.routingNumber;
    }

    handleDone = () => {
        logAmplitudeEvent(pageName, {event: 'Pressed Done Button'});
        this.props.history.push('/business');
    }

    render() {
        Log.info({state: this.state, props: this.props}, `LinkBankSuccessPage state/props`);

        const DoneButton = () => {
            return (
                <Buttons.Primary onClick={this.handleDone}>
                    DONE
                </Buttons.Primary>
            )
        }

        return (
            <Container>
                <LearnMoreModal/>
                {this.props.isLoading &&
                    <PageHeader progress="100%" />
                }
                {!this.props.isLoading &&
                    <PageHeader progress="100%">
                        <div>
                            Updated successfully!
                        </div>
                        <div />
                    </PageHeader>
                }
                <LoaderWithMessage loading={this.props.isLoading} />
                {!this.props.isLoading &&
                    <>
                        <img className={styles.image} src={image} alt="piggy-bank" />
                        <div className="row" style={{ marginBottom: "5px" }}>
                            <div className="col">
                                <div className={styles.innerText}>
                                    Your new bank account has linked and updated successfully.
                                </div>
                            </div>
                        </div>
                        {DoneButton()}
                    </>
                }
            </Container>
        );
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps, {
    getMerchant,
    updateMerchant,
    logOut,
    setError
}
)(LinkBankSuccessPage);
