import {
    SETTINGS_UPDATE_USER,
    SETTINGS_UPDATE_BUSINESS
} from '../actions/types'

const initState = {
    fieldUpdateStatus: {
        user: {},
        business: {}
    }
}

const settingsReducer = (state = initState, action) => {
    switch (action.type) {
        case SETTINGS_UPDATE_USER:
            return {
                ...state,
                ...updateUserSettingsData(action.payload, state),
                userUpdateRequestId: action.payload.userUpdateRequestId
            }
        case SETTINGS_UPDATE_BUSINESS:
            return {
                ...state,
                ...updateBusinessSettingsData(action.payload, state)
            }
        default:
            return state
    }
}

const updateUserSettingsData = (payload, state) => {

    const updatedUserId = payload.userId;

    return {
        fieldUpdateStatus: {
            ...state.fieldUpdateStatus,
            user: {
                [payload.updatedFieldName]: !!updatedUserId
            }
        }
    }
}

const updateBusinessSettingsData = (payload, state) => {

    const merchantId = payload.id;

    return {
        fieldUpdateStatus: {
            ...state.fieldUpdateStatus,
            business: {
                [payload.updatedFieldName]: !!merchantId
            }
        }
    }
}

export default settingsReducer;
