import React from "react"
import {connect} from "react-redux";
import classNames from "classnames";
import Modal from "@wisetack/shared-ui/components/Modal"

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {CustomerInformation} from "../components/CustomerInformation"
import {ServiceDescription} from "../components/ServiceDescription"
import {TransactionDetails} from "../components/TransactionDetails"
import {IdInformation} from "../components/IdInformation"
import {RequestDetails} from "../components/RequestDetails"
import {
    getMerchant,
    getTransaction,
    getVerticalMessages,
    sendPaymentReminder,
    setError,
    submitRefund
} from "../store/actions/merchantActions";
import {
    customizeErrorMessage,
    formatAliasText,
    formatAmount,
    formatBorrowerName,
    formatDate,
    formatRemindAgainAt
} from "../utils/format";

import accepted from "../assets/img/accepted.svg"
import authorized from "../assets/img/authorized.svg"
import canceled from "../assets/img/canceled.svg"
import confirmed from "../assets/img/confirmed.svg"
import declined from "../assets/img/declined.svg"
import expired from "../assets/img/expired.svg"
import initiated from "../assets/img/initiated.svg"
import settled from "../assets/img/settled.svg"
import refund from "../assets/img/refund-icon-big.svg"
import info from "@wisetack/assets/img/info-light.svg"

import styles from "./TransactionDetailPage.module.scss";
import {LoanStatusHistory} from "../components/LoanStatusHistory";

const stateNames = [
    "isLoading",
    "errorMessage",
    "transaction",
    "reminderList",
    "reminderRequestId",
    "reminderMessage",
    "reminderBlocked",
    "user",
    "users",
    "merchant"
]

const pageName = "Transaction Detail Page"
const logProps = {page: pageName}

class TransactionDetailPage extends React.Component {
    state = {
        errorMessage: "",
        reminderList: null,
        sending: false,
        sent: false,
        verticalMessagesLoading: false
    }

    componentDidMount() {
        if (!this.props.user) {
            this.props.history.push("/")
            return;
        }
        if (!this.props.transaction) {
            Log.error('Transaction data not found.')
            this.props.history.push("/transactions");
            return;
        }
        window.scrollTo(0, 0);
        logProps.loanId = this.props.transaction.id
        logProps.userId = this.props.user.userId
        logProps.merchantId = this.props.user.merchantId
        logAmplitudeEvent(pageName, logProps);
        this.props.sendPaymentReminder(
            this.props.transaction.id,
            this.props.user.merchantId,
            this.props.user.userId,
            'GET'
        );

        if (this.props.merchant) {
            this.setState({
                verticalMessagesLoading: true
            })
            this.props.getVerticalMessages(this.props.merchant.vertical, 'portal-transaction-details-page');
        } else {
            const merchantId = localStorage.getItem("merchant:id");
            this.props.getMerchant(merchantId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.reminderRequestId && this.props.reminderRequestId !== prevProps.reminderRequestId) {
            let sent = false;
            if (this.state.sending) {
                sent = true;
            }
            this.setState({
                reminderList: this.props.reminderList,
                sending: false,
                sent
            })
        }
        if (!!this.props.merchant && !this.props.messages && !this.state.verticalMessagesLoading) {
            this.setState({
                verticalMessagesLoading: true
            })
            this.props.getVerticalMessages(this.props.merchant.vertical, 'portal-transaction-details-page');
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = {}
        let errorMessage = nextProps.errorMessage
        if (errorMessage) {
            if (prevState.rawError !== errorMessage) {
                newState.rawError = errorMessage;
                logAmplitudeEvent("Error message", {
                    ...logProps,
                    errorMessage
                });
                const fieldsError = customizeErrorMessage(errorMessage)
                if (fieldsError) {
                    if (fieldsError.token === 'The Token has expired') {
                        nextProps.history.push("/");
                        return;
                    }
                }
                newState.errorMessage = errorMessage
            }
            newState.sending = false 
        } else {
            if(prevState.errorMessage) {
                newState.errorMessage = null
            }
        }
        return newState;
    }

    isLoading() {
        return this.props.isLoading || !this.props.messages;
    }

    handleBack = () => {
        this.props.setError(null);
        this.props.history.push("/transactions");
    }

    handleRefund = () => {
        logAmplitudeEvent("Pressed Issue a Refund Button", logProps);
        this.props.submitRefund(
            this.props.transaction.id
        );
    }

    handleSend = () => {
        if (this.isLoading() || this.state.sent || this.state.sending) {
            return;
        }
        if (!this.props.user) {
            this.props.history.push("/")
        }
        if (this.props.reminderBlocked) {
            this.props.setError("Reminder send blocked.");
            return;
        }
        logAmplitudeEvent("Pressed Send Payment Reminder Button", logProps);
        this.setState({sending: true})
        this.props.sendPaymentReminder(
            this.props.transaction.id,
            this.props.user.merchantId,
            this.props.user.userId,
            'CREATE'
        );
    }

    isButtonDisabled = () => {
        if (this.props.reminderBlocked) return true;
        return false;
    };

    handleRefresh = () => {
        this.props.getTransaction(this.props.user.merchantId, this.props.transaction.id)
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `TransactionDetailPage state/props`);
        const transaction = this.props.transaction || {};
        const user = this.props.user || {};
        const statusAlias = formatAliasText(transaction.status);
        const name = formatBorrowerName(transaction.consumer);
        const amount = formatAmount(transaction);
        const statusDate = transaction.statusChangedOn ? transaction.statusChangedOn : transaction.tilaAcceptedOn;
        const displayDate = formatDate(statusDate);

        const btnDisabled = this.isButtonDisabled();
        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled || this.state.sending || this.state.sent,
            [styles.buttonEnabled]: !(btnDisabled || this.state.sending || this.state.sent),
        });

        const SendButton = () => {
            if (!this.state.reminderList) {
                return null;
            }
            // any of LoanApplicationStatus.DOCUMENTS_SIGNED, LoanApplicationStatus.OFFER_AVAILABLE, LoanApplicationStatus.ACCOUNT_LINKED, LoanApplicationStatus.CONDITIONAL_APPROVAL_ACCOUNT_LINKED
            if (transaction.status !== 'Accepted' && transaction.status !== 'Authorized') {
                return null;
            }
            let caption = "SEND PAYMENT REQUEST"
            return (
                <div className="row" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                    <div className="col">
                        <button className={btnClasses} onClick={this.handleSend}>
                            {caption}
                        </button>
                    </div>
                </div>
            )
        }

        const RefundButton = () => {
            // Disabled while not implemented
            if (!!true) {
                return null;
            }
            if (this.isLoading()) {
                return null;
            }
            if (transaction.status !== 'Settled') {
                return null;
            }
            return (
                <div className={styles.refund} onClick={this.handleRefund}>Issue a refund</div>
            )
        }

        const ReminderInfo = () => {
            if (this.isLoading()) {
                return null;
            }
            let message = null;
            if (this.state.reminderList && this.state.reminderList.length > 0) {
                message = formatRemindAgainAt(this.state.reminderList[0].createdAt)
            }
            if (!message) {
                return null;
            }
            if (btnDisabled || this.state.sending || this.state.sent) {
                return (<div className={styles.reminder}>{message}</div>)
            } else {
                return null;
            }
        }
        
        const statusDescription = (status, statusAlias) => {
            if (!status) {
                return ''
            }
            switch (status) {
                case "Accepted":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={accepted} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The  customer has accepted and signed the loan terms.</p>
                            </div>
                            );
                case "Authorized":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={authorized} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The customer’s application has been approved and an offer is waiting to be accepted.</p>
                            </div>
                            );
                case "Canceled":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={canceled} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The customer has canceled their loan application. The customer must reapply to receive a new loan approval.</p>
                            </div>
                            );
                case "Confirmed":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={confirmed} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The customer has confirmed their loan and their purchase is complete.</p>
                            </div>
                            );
                case "Declined":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={declined} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>All necessary data was provided but the application was declined due to identity or credit reasons.</p>
                            </div>
                            );
                case "Expired":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={expired} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The customer did not accept the terms before the expiration date.</p>
                            </div>
                            );
                case "Initiated":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={initiated} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>The customer has started a loan application and needs to provide additional information to complete their application.</p>
                            </div>
                            );
                case "Settled":
                    return (
                            <div>
                                <div><img className={styles.modalImage} src={settled} alt="statusIcon"/></div>
                                <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                                <p className={styles.modalParagraph}>We sent funds to your bank account. It usually takes 1-3 business days to arrive.</p>
                            </div>
                            );
                case "Refunded":
                    return (
                        <div>
                            <div><img className={styles.modalImage} src={refund} alt="statusIcon"/></div>
                            <div className={styles.modalStatus}><span>{statusAlias}</span></div>
                            <p className={styles.modalParagraph}>The customer has canceled their loan. The disbursed loan amount has been refunded.</p>
                        </div>
                    );
                default:
                    return null;
            }
        }

        return (
            <Container>
                <div className={styles.back} onClick={this.handleBack}>
                    <span className="material-icons">arrow_back</span>
                </div>
                <div className={styles.transaction}>
                    <div className="row">
                        <div className="col" style={{zIndex: "-1"}}> </div>
                        <div className="col">
                            <div className={styles.refresh}>
                                <span className="material-icons"
                                      style={{cursor: "pointer"}}
                                      onClick={this.handleRefresh}>
                                refresh</span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.name}>{name}</div>
                    <div className={styles.amount}>{amount}</div>
                    
                    {transaction.status === "Accepted" && <div><img className={styles.image} src={accepted} alt="statusIcon"/></div>}
                    {transaction.status === "Authorized" && <div><img className={styles.image} src={authorized} alt="statusIcon"/></div>}
                    {transaction.status === "Canceled" && <div><img className={styles.image} src={canceled} alt="statusIcon"/></div>}
                    {transaction.status === "Confirmed" && <div><img className={styles.image} src={confirmed} alt="statusIcon"/></div>}
                    {transaction.status === "Declined" && <div><img className={styles.image} src={declined} alt="statusIcon"/></div>}
                    {transaction.status === "Expired" && <div><img className={styles.image} src={expired} alt="statusIcon"/></div>}
                    {transaction.status === "Initiated" && <div><img className={styles.image} src={initiated} alt="statusIcon"/></div>}
                    {transaction.status === "Settled" && <div><img className={styles.image} src={settled} alt="statusIcon"/></div>}

                    <div className={styles.status}>
                        <span>{statusAlias}</span>
                        <img className={styles.icon} src={info} alt="&#9432;" data-toggle="modal" data-target="#statusDescriptionModal"/>
                    </div>
                    <div className={styles.date}>{displayDate}</div>
                    {SendButton()}
                    <ReminderInfo />
                    <LoaderWithMessage loading={this.isLoading()} />
                    {!this.isLoading() &&
                        <div className={styles.details}>
                            <CustomerInformation item={transaction}/>
                            <ServiceDescription item={transaction}/>
                            <TransactionDetails item={transaction}/>
                            <LoanStatusHistory item={transaction}/>
                            <IdInformation item={transaction} messages={this.props.messages}/>
                            <RequestDetails partnerName={user.partnerName} initiatedBy={transaction.initiatorUserName}/>
                        </div>
                    }
                    {RefundButton()}
                </div>
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                <Modal id="statusDescriptionModal">
                    {statusDescription(transaction.status, statusAlias)}
                </Modal>
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        messages: state.merchant.messages
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {submitRefund, sendPaymentReminder, setError, getTransaction, getVerticalMessages, getMerchant}
)(TransactionDetailPage);

