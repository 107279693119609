import React, {useState, useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";
import deepEqual from "deep-equal";

import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";

import Container from "../components/Container";
import DropdownMultiSelect from "../components/DropdownMultiSelect";
import FiltersHeader from "../components/FiltersHeader";
import useAppFlow from "../hooks/useAppFlow";
import {getMerchant, applyPrequalFilter, loadDynamicUserFilter} from "../store/actions/merchantActions";
import styles from "./PrequalFiltersPage.module.scss";
import {countSelected, transactionsFilter} from "../utils/format";
import FilterApplyButton from "../components/FilterApplyButton";

const pageName = 'Prequal Filters Page'

function PrequalFiltersPage({getMerchant, applyPrequalFilter, loadDynamicUserFilter}) {
    const history = useHistory();
    const originalFilter = useSelector(state => state.merchant.prequalFilter);

    const [filter, setFilter] = useState(originalFilter)

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const {logAmplitude, merchant} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress 
    const filterSelections = countSelected(filter)

    function useDynamicFilterEffects() {
        const users = useSelector(state => state.merchant.prequalFilter.users);
        
        useEffect(() => {
            if (Object.keys(filter.users).length === 0){
                let merchantId;
                if (merchant) {
                    merchantId = merchant.id;
                } else {
                    merchantId = localStorage.getItem("merchant:id");
                }
                loadDynamicUserFilter(merchantId, newRequest());
            }
        }, []);

        useEffect(() => {
            setFilter({...filter, users});
        }, [users]);    
    };
    useDynamicFilterEffects();

    const handleSelectItemClick = (groupId, itemId, item) => {
        const selected = !item.selected
        const newFilter = JSON.parse(JSON.stringify(filter)) // deep clone
        newFilter[groupId][itemId].selected = selected
        setFilter(newFilter)
    }

    const resetAllFilters = () => {
        const newFilter = JSON.parse(JSON.stringify(filter)) // deep clone
        console.log(newFilter)
        Object.values(newFilter).forEach(group => {
            if (typeof group === 'object') {
                Object.entries(group).forEach(([filterId, filter]) => {
                    filter.selected = false;
                })
            }
        })
        setFilter(newFilter)
    }

    const isApplyEnabled = () => {
        return !deepEqual(originalFilter, filter)
    }

    const applyFilter = () => {
        applyPrequalFilter(filter)
        logAmplitude('Apply button clicked', {filter: transactionsFilter(filter)})
        history.push("/prequals")
    }

    return <Container>
        <FiltersHeader
            onClose={() => history.push("/prequals")}
            onReset={filterSelections > 0 ? resetAllFilters : null}
        />
        <LoaderWithMessage loading={loading} />
        { !loading && <>
            <div className={styles.filters}>
                <DropdownMultiSelect id="statuses" caption="Prequal status" filters={filter.statuses} onSelectItemClick={handleSelectItemClick}/>
                <DropdownMultiSelect id="years" caption="Year" filters={filter.years} onSelectItemClick={handleSelectItemClick}/>
                <DropdownMultiSelect id="months" caption="Month" filters={filter.months} onSelectItemClick={handleSelectItemClick}/>
                <DropdownMultiSelect id="users" caption="User" filters={filter.users} onSelectItemClick={handleSelectItemClick}/>
            </div>
            <Error>{requestError}</Error>
            <FilterApplyButton filterSelections={filterSelections} onClick={isApplyEnabled() ? applyFilter : null}/>
        </>
        }
    </Container>
}

export default connect(null, {getMerchant, applyPrequalFilter, loadDynamicUserFilter})(
    PrequalFiltersPage
);

