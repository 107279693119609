import React from "react";

function Container({ children }) {
  return (
    <div
      className="container"
      style={{
        backgroundColor: "#ffffff",
        minWidth: "250px",
        maxWidth: "375px",
        paddingBottom: "30px",
        paddingLeft: "30px",
        paddingRight: "30px"
      }}
    >
      {children}
    </div>
  );
}

export default Container;
