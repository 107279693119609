import {connect} from "react-redux";
import NavBar from "../components/NavBar";
import Container from "../components/Container";
import React from "react";
import key_color from "../assets/img/key_color.svg"
import styles from "./UserManagementPage.module.scss"
import classNames from "classnames";
import ButtonFilled from "../components/ButtonFilled";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import Log from "@wisetack/shared-ui/utils/Log";
import {isAdmin, verifyWisetackToken} from "../utils/auth";
import {getMerchant, logOut, setError, setMerchantUser, getMerchantUsers, setUserInEdit} from "../store/actions/merchantActions";
import {USER_ROLE_DISPLAY_MAPPING} from "../utils/format"
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "merchantUsers",
    "users",
    "user"
]

const pageName = "User management page"
const logProps = {page: pageName}

class UserManagementPage extends React.Component {

    state = {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const merchantChanged = prevProps.user
            && this.props.user
            && prevProps.user.merchantId !== this.props.user.merchantId

        if (!isAdmin(this.props.user) && merchantChanged) {
            this.props.history.push("/account_settings");
        }
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        if (!isAdmin(this.props.user)) {
            this.props.history.push("/account_settings");
            return;
        }

        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId
        if (this.props.user) {
            logProps.userId = this.props.user.userId
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)

        if (!this.props.merchantUsers || !(merchantId in this.props.merchantUsers)) {
            this.props.getMerchantUsers(merchantId);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    handleEditClick = (item) => {
        this.props.setUserInEdit(item);
        this.props.history.push("/user_edit_settings")

        console.log(" edit pressed")
        console.log(item)
    }

    handleAdd = () => {
        this.props.history.push("/user_add");
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)

        if (isAdmin(e.data)
            && e.alias === 'account'
            && !(e.data.merchantId in this.props.merchantUsers)) {
            this.props.getMerchantUsers(e.data.merchantId)
        }
    }

    onBackArrowClick = () => {
        this.props.history.goBack();
    }

    render() {

        const UserRow = ({item, onEditClick}) => {
            const displayRole = USER_ROLE_DISPLAY_MAPPING[item.userRole];
            return (
                <div className={classNames("row", styles.user_row)}>
                    <div className={classNames("col", styles.text)}>
                        <div>{item.firstNameEncrypted} {item.lastNameEncrypted}</div>
                        <div className={styles.role}>{displayRole}</div>
                    </div>
                    <div className={classNames("col", styles.edit)} onClick={() => onEditClick(item)}>{"Edit"}</div>
                </div>
            )
        }

        let users = [];
        if (this.props.merchant && this.props.merchantUsers) {
            const merchantId = this.props.merchant.id;
            if (merchantId in this.props.merchantUsers) {
                users = Object.values(this.props.merchantUsers[merchantId])
            }
        }

        const selectOptions = users.map(user => {
            return (
                <UserRow key={user.userId}
                            item={user}
                            onCheckboxClick={this.handleItemClick}
                            onEditClick={this.handleEditClick}
                />
            )
        })

        return (
            <Container>
                <NavBar title={"User management"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    <div className={styles.title}>Users</div>
                    <img src={key_color} alt={"key_color.svg"}/>
                </div>
                <LoaderWithMessage loading={this.props.isLoading}/>
                {!this.props.isLoading &&
                <>
                    <div className={styles.users}>
                        {selectOptions}
                    </div>
                    <ButtonFilled onClick={this.handleAdd}>
                        ADD USER
                    </ButtonFilled>
                </>
                }
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        getMerchant,
        setMerchantUser,
        logOut,
        setError,
        getMerchantUsers,
        setUserInEdit
    }
)(UserManagementPage);