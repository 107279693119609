import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import image from "@wisetack/assets/img/bank_icon.svg";

import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import styles from "./LinkBankReviewPage.module.scss";
import {getMerchant, logOut, setError, updateMerchant} from "../store/actions/merchantActions";
import {verifyWisetackToken} from "../utils/auth";
import {redirectToEntryPage} from "../utils/transitions";
import {stateFromProps} from "../utils/state";

const pageName = 'Review Bank Info';

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "merchantUpdate",
    "manualAccountData"
]

const logProps = {page: pageName}

class LinkBankReviewPage extends React.Component {

    state = {
        pin: "",
        errors: {},
        suggestions: {},
        username: null,
        noAccountData: false
    };

    isAccountDataProvided = (data) => {
        if (!data || Object.keys(data).length < 6) {
            return false;
        }
        if (!data.accountNumber || !data.accountNumberCopy || data.accountNumber !== data.accountNumberCopy) {
            return false;
        }
        return data.accountName && data.bankName && data.subtype && data.routingNumber;
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)

        this.setState({noAccountData: !this.isAccountDataProvided(this.props.manualAccountData)});
        if (this.state.noAccountData) {
            this.props.history.push("/link_bank_manual");
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    handleSubmit = () => {
        if (this.state.noAccountData) {
            return;
        }
        logAmplitudeEvent(pageName, {event: 'Pressed Submit Button'});
        const paymentAccount = {
            accountName: this.props.manualAccountData.accountName,
            subtype: this.props.manualAccountData.subtype,
            accountNumber: this.props.manualAccountData.accountNumber,
            routingNumber: this.props.manualAccountData.routingNumber
        }
        this.props.updateMerchant(this.props.merchant.id, {paymentAccount});
        this.props.history.push('/bank_link_success');
    }

    handleEdit = () => {
        logAmplitudeEvent(pageName, {event: 'Pressed Edit Button'});
        this.props.history.push('/link_bank_manual');
    }

    render() {
        const SubmitButton = () => {
            return (
                <button className={classNames("btn", "btn-block", styles.submitButton)} onClick={this.handleSubmit}>
                    SUBMIT
                </button>
            )
        }

        const EditButton = () => {
            return (
                <button className={classNames("btn", "btn-block", styles.editButton)} onClick={this.handleEdit}>
                    EDIT
                </button>
            )
        }

        return <Container>
            <PageHeader progress="80%">
                <div>
                    Review bank info
                </div>
                <div/>
            </PageHeader>
            <div className={styles.panel}>
                <img className={styles.logo} src={image} alt="bank-logo"/>
                <div className={styles.label}>Name on account</div>
                <div className={styles.value}>{this.props.manualAccountData.accountName}</div>
                <div className={styles.label}>Bank name</div>
                <div className={styles.value}>{this.props.manualAccountData.bankName}</div>
                <div className={styles.label}>Account type</div>
                <div className={styles.subtype}>{this.props.manualAccountData.subtype}</div>
                <div className={styles.label}>Account number</div>
                <div className={styles.value}>{this.props.manualAccountData.accountNumber}</div>
            </div>
            <SubmitButton/>
            <EditButton/>
        </Container>
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps, {
        getMerchant,
        updateMerchant,
        logOut,
        setError
    }
)(LinkBankReviewPage);
