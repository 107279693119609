import React from "react";
import styles from "./UserAddPage.module.scss"
import FormInput from "@wisetack/shared-ui/components/FormInput";
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import ButtonFilled from "../components/ButtonFilled";
import {connect} from "react-redux";
import {addUser, getMerchant, logOut, setError, setMerchantUser, getMerchantUsers} from "../store/actions/merchantActions";
import {formatUsPhoneToDomestic} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {appTransition, redirectToEntryPage} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import {isAdmin, verifyWisetackToken} from "../utils/auth";
import {UserFieldValidator} from "../utils/UserFieldValidator";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import FormRadioButton from "@wisetack/shared-ui/components/FormRadioButton";
import plane from "@wisetack/assets/img/paper-plane.svg";
import ErrorWithIcon from "@wisetack/shared-ui/components/ErrorWithIcon";
import {USER_ROLE_DISPLAY_MAPPING} from "../utils/format"

const stateNames = [
    "isLoading",
    "users",
    "merchant",
    "user",
    "errorMessage",
    "addUserRequestId",
    "merchantUsers"
]

const pageName = "Add new user page"
const logProps = {page: pageName}
const validator = new UserFieldValidator(pageName);

const formFields = {
    firstName: {
        label: 'First name',
        pattern: '^[ -~]*$'
    },
    lastName: {
        label: 'Last name',
        pattern: '^[ -~]*$'
    },
    phoneNumber: {
        label: 'Mobile phone number',
        inputMode: 'numeric',
        pattern: '[0-9]*',
        autoFormat: formatUsPhoneToDomestic,
        validatorName: 'validateMobileNumber',
    },
    email: {
        label: 'Email address',
        validatorName: 'validateEmail',
        suggestFn: function (suggestion) {
            this.setState({suggestions: {email: suggestion}});
        }
    },
    userRole: {}
}

const setValidators = (props) => {
    validator.props = props
    for (const value of Object.values(formFields)) {
        if (value.validatorName) {
            value.validator = validator[value.validatorName];
        }
    }
}

const MODE = {
    INPUT: 'INPUT',
    CONFIRMATION: 'CONFIRMATION'
}

class UserAddPage extends React.Component {

    state = {
        errors: {},
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        userRole: 'ADMIN',
        mode: MODE.INPUT
    }

    handleSendInvite = () => {
        let payload = this.preparePayload();
        this.props.addUser(this.props.merchant.id, payload);
    }

    onUserRoleChange = (clickedRole) => {
        this.setState({
            userRole: clickedRole
        });
    }

    preparePayload = () => {

        const payload = {}

        for (const name of Object.keys(formFields)) {
            if (name === 'phoneNumber') {
                payload[name] = this.formatMobileNumber(this.state[name]);
            } else {
                payload[name] = this.state[name];
            }
        }

        return payload
    }

    inputCleanUp = (name, val) => {
        if (val && formFields[name] && formFields[name].blacklist) {
            return val.replace(formFields[name].blacklist, "");
        }
        return val;
    }

    autoFormat = (name, val) => {
        if (!!val && typeof formFields[name].autoFormat === "function") {
            return formFields[name].autoFormat(val);
        }
        return val;
    }

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;

        val = this.inputCleanUp(name, val)
        val = this.autoFormat(name, val)

        this.setState({
            [name]: val
        });
        this.setError(name, "");
    };

    validateField = (name, val) => {
        if (typeof formFields[name].validator === "function") {
            if (formFields[name].suggestFn) {
                return formFields[name].validator(val, formFields[name].suggestFn.bind(this));
            } else {
                return formFields[name].validator(val);
            }
        }
        if (!val && !formFields[name].optional) {
            const message = formFields[name].mandatory || `Enter ${formFields[name].label}`
            validator.log(formFields[name].label, message);
            return message;
        }
        return "";
    };

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        appTransition(e, this.props)
    }

    onBackArrowClick = () => {
        this.props.history.push("/user_management");
    }

    formatMobileNumber = (mobileNumber) => {
        mobileNumber = mobileNumber.replace(/\D/g, "");
        if (mobileNumber.length === 10) {
            return "+1" + mobileNumber;
        } else if (mobileNumber.length === 11) {
            return "+" + mobileNumber;
        }
        return mobileNumber;
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    handleOnBlur = (e) => {
        let val = e.target.value;
        let name = e.target.name;
        const error = this.validateField(name, val);
        this.setError(name, error);
    }

    setError = (name, error) => {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    getValue = name => {
        if (this.state[name]) {
            return this.state[name];
        }
        return "";
    };

    isSendInviteButtonEnabled = () => {
        if (this.props.isLoading) return false;
        for (const key in this.state.errors) {
            if (this.state.errors[key]) return false;
        }
        for (const key of Object.keys(formFields)) {
            if (!this.state[key]) {
                return false;
            }
        }
        return true;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

        const merchantChanged = prevProps.user
            && this.props.user
            && prevProps.user.merchantId !== this.props.user.merchantId

        if (merchantChanged) {
            this.props.history.push("/user_management");
        }

        if (this.props.addUserRequestId && this.props.addUserRequestId !== prevProps.addUserRequestId) {
            if (this.props.errorMessage) {
                return;
            }
            this.switchToMode(MODE.CONFIRMATION);
        }
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        if (!isAdmin(this.props.user)) {
            this.props.history.push("/account_settings");
            return;
        }

        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);

        setValidators(logProps)
        this.props.setError(null)

        if (!this.props.merchantUsers || !(merchantId in this.props.merchantUsers)) {
            this.props.getMerchantUsers(merchantId);
        }
    }

    isCurrentMode = (mode) => {
        return this.state.mode === mode;
    }

    switchToMode = (mode) => {
        this.setState({mode: mode})
    }

    render() {

        const formInput = (fieldName) => {

            return (
                <FormInput
                    name={fieldName}
                    label={formFields[fieldName].label}
                    value={this.getValue(fieldName)}
                    onChange={this.handleOnChange}
                    onBlur={this.handleOnBlur}
                    errors={this.state.errors}
                    inputMode={formFields[fieldName].inputMode}
                    pattern={formFields[fieldName].pattern}
                    innerRef={formFields[fieldName].innerRef}
                    fieldsError={this.props.fieldsError}
                    suggestions={this.state.suggestions}
                    readOnly={formFields[fieldName].readOnly}
                />
            )
        }

        return (
            <Container>
                <NavBar title={"Add user"}
                        users={this.state.users}
                        onMenuItemClick={this.handleMenuItemClick}
                        onBackArrowClick={this.onBackArrowClick}
                />
                <div className={styles.header}>
                    {this.isCurrentMode(MODE.INPUT) &&
                        <div className={styles.title}>Complete the form to send an invitation</div>
                    }
                    {this.isCurrentMode(MODE.CONFIRMATION) &&
                        <>
                            <div className={styles.title}>Invite sent!</div>
                            <img src={plane} alt={plane}/>
                        </>
                    }
                </div>
                <div className={styles.error_banner_wrapper}>
                    <ErrorWithIcon>{this.props.errorMessage}</ErrorWithIcon>
                </div>
                <LoaderWithMessage loading={this.props.isLoading}/>
                {!this.props.isLoading && this.isCurrentMode(MODE.INPUT) &&
                <>
                    <div className={styles.content}>
                        <div>
                            <div className={styles.names}>
                                <span className={styles.firstName}>
                                    <FormRow>{formInput('firstName')}</FormRow>
                                </span>
                                <FormRow>{formInput('lastName')}</FormRow>
                            </div>
                            <FormRow>{formInput('phoneNumber')}</FormRow>
                            <FormRow>{formInput('email')}</FormRow>
                            <label style={{color: "#6f7595"}} className="bmd-label-floating">
                                {"Permission setting"}
                            </label>
                            <div className={styles.permissions}>
                                <div className={styles.admin}>
                                    <FormRadioButton
                                        label={USER_ROLE_DISPLAY_MAPPING['ADMIN']}
                                        checked={'ADMIN' === this.state.userRole}
                                        value={'ADMIN'}
                                        onClick={this.onUserRoleChange}
                                    />
                                </div>
                                <div className={styles.standard}>
                                    <FormRadioButton
                                        label={USER_ROLE_DISPLAY_MAPPING['STANDARD']}
                                        checked={'STANDARD' === this.state.userRole}
                                        value={'STANDARD'}
                                        onClick={this.onUserRoleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <ButtonFilled onClick={this.handleSendInvite} disabled={!this.isSendInviteButtonEnabled()}>
                        SEND INVITE
                    </ButtonFilled>
                </>
                }
                {!this.props.isLoading && this.isCurrentMode(MODE.CONFIRMATION) &&
                    <div className={styles.description}>
                        SMS message sent to {this.state.firstName} {this.state.lastName} with a link to the invitation.
                    </div>
                }
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        setMerchantUser,
        setError,
        addUser,
        getMerchantUsers
    }
)(UserAddPage);