import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useHistory} from "react-router";
import classNames from "classnames";

import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import Error from "@wisetack/shared-ui/components/Error";

import Container from "../components/Container";
import {Loader} from "../components/Loader";

import {getMerchant, getPrequal} from "../store/actions/merchantActions";
import useAppFlow from "../hooks/useAppFlow";
import {formatBorrowerName, formatDate, formatPrequalAmount, formatPrequalStatusInfo} from "../utils/format";
import styles from "./PrequalDetailPage.module.scss";
import info from "@wisetack/assets/img/info-light.svg";
import {PrequalCustomerInformation} from "../components/PrequalCustomerInformation";
import {PrequalOfferTerms} from "../components/PrequalOfferTerms";
import Modal from "@wisetack/shared-ui/components/Modal";
import ButtonOutlined from "../components/ButtonOutlined";
import {isNewTransactionAvailable} from "@wisetack/shared-ui/utils/transactions";
import {RequestDetails} from "../components/RequestDetails";

const pageName = 'Prequal Detail Page'

const DetailHeader = ({onBack, onRefresh}) => {
    return (
        <div className={classNames("row", styles.header)}>
            <div className={"col"} style={{maxHeight: "20px"}}>
                <div className={classNames("material-icons", styles.back)} onClick={onBack}>arrow_back</div>
            </div>
            <div className={"col"} style={{maxHeight: "20px"}}>
                <div className={styles.refresh}>
                    <span className="material-icons" onClick={() => onRefresh()}>refresh</span>
                </div>
            </div>
        </div>
    )
}

const SendLoanButton = ({user, status, onClick}) => {
    if (!isNewTransactionAvailable(user)) {
        return null
    }
    if (status !== 'PREQUALIFIED') {
        return null
    }
    return <div style={{marginBottom: "30px"}}>
        <ButtonOutlined onClick={onClick}>SEND LOAN APPLICATION</ButtonOutlined>
    </div>
}

function PrequalDetailPage({getMerchant, getPrequal}) {
    const history = useHistory();

    const prequal = useSelector(state => state.merchant.prequal);

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const [icon, setIcon] = useState("");
    const {merchant, user, logAmplitude} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress

    const name = formatBorrowerName(!!prequal && prequal.customerInformation);
    const amount = formatPrequalAmount(prequal, 'Up to ');
    const displayDate = formatDate(!!prequal && prequal.createdAt);
    const [,status,statusDescription,iconName] = formatPrequalStatusInfo(!!prequal && prequal.status);
    const customer = !!prequal ? prequal.customerInformation : {}
    const offerTerms = !!prequal && prequal.offerTerms
    const merchantId = !!merchant && merchant.id
    const appId = !!prequal && prequal.id

    useEffect(() => {
        if (merchantId && appId) {
            getPrequal(merchantId, appId, newRequest())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId, appId])

    useEffect(() => {
        // dynamically import icon
        if (iconName) {
            import(`../assets/img/${iconName}`).then(icon => setIcon(icon.default))
        }
    }, [iconName])

    useEffect(() => {
        if (requestError) {
            logAmplitude('Error', {error: requestError})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestError])

    const handleBackClick = () => {
        logAmplitude('Back button clicked')
        history.push("/prequals");
    }

    const handleRefreshClick = () => {
        logAmplitude('Refresh button clicked')
        if (merchantId && appId) {
            getPrequal(merchantId, appId, newRequest())
        }
    }

    const handleSendLoan = () => {
        logAmplitude('Send loan application button clicked')
        history.push("/send_transaction");
    }

    return (
        <Container>
            <DetailHeader onBack={handleBackClick} onRefresh={handleRefreshClick}/>
            <div className={styles.name}>{name}</div>
            <div className={styles.amount}>{amount}</div>
            { loading || !icon ?  <Loader/> : <>
                {icon && <img className={styles.image} src={icon} alt="statusIcon" onDoubleClick={() => window.open(prequal.prequalLink, "_blank")}/>}
                <div className={styles.status}>
                    <span>{status}</span>
                    <img className={styles.icon} src={info} alt="&#9432;" data-toggle="modal" data-target="#statusDescriptionModal"/>
                </div>
                <div className={styles.date}>{displayDate}</div>
                <SendLoanButton user={user} status={!!prequal && prequal.status} onClick={handleSendLoan}/>
                <PrequalCustomerInformation consumer={customer}/>
                <PrequalOfferTerms offerTerms={offerTerms}/>
                <RequestDetails partnerName={user.partnerName} initiatedBy={prequal.initiatorUserName} expanded={true}/>
                <Error>{requestError}</Error>
            </>
            }
            <Modal id="statusDescriptionModal">
                <div><img className={styles.modalImage} src={icon} alt="statusIcon"/></div>
                <div className={styles.modalStatus}><span>{status}</span></div>
                <p className={styles.modalParagraph}>{statusDescription}</p>
            </Modal>
        </Container>
    )

}

export default connect(null, {getMerchant, getPrequal})(
    PrequalDetailPage
);
