import React from "react";
import styles from "./Option.module.scss";

export function Option({children, onClick}) {

    return (
        <div className={styles.option} onClick={() => onClick()}>
            <div className={styles.info}>
                {children}
            </div>
            <div className={styles.control}>
                <span className="material-icons">keyboard_arrow_right</span>
            </div>
        </div>
    );
}
