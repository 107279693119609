import React from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import uuid from "uuid";

import Log from "@wisetack/shared-ui/utils/Log";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import {MerchantFieldValidator} from "@wisetack/shared-ui/utils/MerchantFieldValidator";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {Loader} from "../components/Loader"

import {
    getMerchant,
    updateMerchant,
    logOut,
    setError,
    setMerchantUser,
    submitPin,
    updateBusiness,
    createLinkToken,
    acceptPlaidToken
} from "../store/actions/merchantActions";
import styles from "./MerchantEmailPinPage.module.scss";
import {stateFromProps} from "../utils/state";
import {redirectToEntryPage} from "../utils/transitions";
import ButtonContainer from "../components/ButtonContainer";
import PlaidLink from "@wisetack/shared-ui/components/PlaidLink";
import {verifyWisetackToken} from "../utils/auth";
import {customizeErrorMessage} from "../utils/format";

const pageName = "Merchant Email Pin Page"

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "submitPinRequestId",
    "linkTokens"
]

const logProps = {page: pageName}

class MerchantEmailPinPage extends React.Component {

    state = {
        pin: "",
        errors: {},
        suggestions: {},
        username: null
    };

    constructor(props) {
        super(props);
        this.validator = new MerchantFieldValidator(pageName);
    }

    componentDidMount() {
        verifyWisetackToken(this.props)
        window.scrollTo(0, 0);

        let merchantId;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }

        if (!this.props.user && this.props.users) {
            let selectedUser = this.props.users.find(user => user.userRole === "ADMIN")
            this.setState({user: selectedUser})
        }

        if (!merchantId) {
            redirectToEntryPage(this.props, 'Merchant ID not found.')
            return;
        }

        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);
        this.props.setError(null)

        if (!this.props.linkTokens || !this.props.linkTokens.flexible) {
            this.props.createLinkToken(this.props.merchant.id, null, uuid.v4());
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let newState = stateFromProps(nextProps, prevState, logProps)
        let errorMessage = nextProps.errorMessage
        if (errorMessage) {
            if (prevState.rawError !== errorMessage) {
                newState.rawError = errorMessage
                const pinError = customizeErrorMessage(errorMessage)
                if (pinError) {
                    newState.suggestions = {pin: pinError['pin']}
                    logAmplitudeEvent("Pin error", {
                        ...logProps,
                        pinError: pinError
                    })
                } else {
                    newState.errorMessage = errorMessage
                    logAmplitudeEvent("Error message", {
                        ...logProps,
                        errorMessage
                    })
                }
            }
        } else {
            if (prevState.errorMessage) {
                newState.errorMessage = null
            }
        }
        return newState;
    }

    componentDidUpdate(prevProps) {
        if (this.props.errorMessage) {
            if (this.props.errorMessage.includes('lockout')) {
                this.props.setError(null);
                this.props.history.push("/account_locked");
                return;
            }
        }
        if (this.props.submitPinRequestId && this.props.submitPinRequestId !== prevProps.submitPinRequestId) {
            if (this.props.user) {
                logAmplitudeEvent("Email pin verification succeed", {
                    ...logProps,
                    userId: this.props.user.userId,
                    merchantId: this.props.user.merchantId
                })
                this.openLink()
            }
        }
    }

    handleOnBlur = e => {
    };

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    handleOnButtonClick = () => {
        logAmplitudeEvent("Pressed Didn't get a code Button", logProps)
        this.props.history.push("/business");
    };

    handleOnChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        if (name === "pin") {
            val = val.replace(/\D/g, "");
            if (val.length > 4) {
                return;
            }
        }
        this.setState({[name]: val});
    };

    handleVerifyPin = (event, openLink) => {
        if (this.state.pin.length === 4) {
            logAmplitudeEvent("Entered pin", this.logProps)
            this.props.submitPin(this.state.pin, this.props.user.emailEncrypted, uuid.v4());
            this.openLink = openLink;
        }
    };

    isButtonDisabled = () => {
        if (this.props.isLoading) return true;

        return this.state.pin.length !== 4;
    };

    getLinkToken = () => {
        let linkToken = null;
        if (this.props.linkTokens && this.props.linkTokens.flexible) {
            linkToken = this.props.linkTokens.flexible.token;
        }
        if (this.state.linkToken) {
            linkToken = this.state.linkToken;
        }
        return linkToken;
    }

    handleOnExit = (err, metadata) => {
        Log.info({err, metadata}, `Plaid Link Exit`);
        logAmplitudeEvent("Plaid Link Exit", {
            ...this.state.logProps,
            err,
            metadata
        })

        this.props.history.push("/link_bank_mode");
    };

    handleOnEvent = (eventName, metadata) => {
        Log.info({eventName, metadata}, `Plaid Link Event`);
        logAmplitudeEvent("Plaid Link Event", {
            ...this.state.logProps,
            eventName,
            metadata
        })
    };

    handleOnSuccess = async (token, metadata) => {
        Log.info({token, metadata}, `Plaid Link Success`);
        let accounts = [];
        if (metadata.accounts) {
            accounts = metadata.accounts.map(acc => {
                return {
                    id: acc.id,
                    mask: acc.mask,
                    name: acc.name,
                    type: acc.type,
                    subtype: acc.subtype,
                    verificationStatus: acc.verification_status || ''
                }
            });
        }
        logAmplitudeEvent("Plaid Link Success", {
            ...this.state.logProps,
            accounts,
            metadata
        })
        const continueRequestId = uuid.v4();
        this.props.acceptPlaidToken(
            this.props.user.merchantId,
            token,
            accounts,
            continueRequestId
        );
        this.props.history.push("/bank_link_success");
    };

    render() {
        Log.info({state: this.state, props: this.props}, `MerchantEmailPinPage state/props`);

        const textButtonClasses = classNames({
            [styles.text_button]: true
        });

        let btnDisabled = this.isButtonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        const VerifyButton = () => {
            let linkToken = this.getLinkToken();
            if (!linkToken) {
                return null;
            }
            return (
                <ButtonContainer>
                    <PlaidLink
                        onClick={this.handleVerifyPin}
                        disabled={false}
                        className={btnClasses}
                        clientName="Wisetack"
                        token={linkToken}
                        env={process.env.REACT_APP_PLAID_ENV}
                        onExit={this.handleOnExit}
                        onEvent={this.handleOnEvent}
                        onSuccess={this.handleOnSuccess}
                        handlerName="linkPlaidRegularHandler"
                    >
                        VERIFY
                    </PlaidLink>
                </ButtonContainer>
            )
        }

        return (
            <Container>
                <PageHeader progress="">
                    <div>We just emailed you</div>
                    <div style={{paddingLeft: "15px", textAlign: "center"}}>
                        Please enter the verification code we sent to the email address on file to update your bank
                        account information
                    </div>
                </PageHeader>
                {this.props.isLoading && <Loader/>}
                <div className={styles.inputs}>
                    {!this.props.isLoading && (
                        <>
                            <FormRow>
                                <FormInput
                                    autoFocus
                                    type="text"
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                    name="pin"
                                    label="Enter the 4-digit code"
                                    value={this.state.pin}
                                    onChange={this.handleOnChange}
                                    onBlur={this.handleOnBlur}
                                    suggestions={this.state.suggestions}
                                />
                            </FormRow>
                            <Error>{this.state.errorMessage}</Error>
                            <VerifyButton/>
                            <div className="row">
                                <div className="col">
                                    <div
                                        className={textButtonClasses}
                                        onClick={this.handleOnButtonClick}
                                    >
                                        Didn't get a code?
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </Container>
        );
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        logOut,
        getMerchant,
        updateMerchant,
        setMerchantUser,
        updateBusiness,
        setError,
        submitPin,
        createLinkToken,
        acceptPlaidToken
    }
)(MerchantEmailPinPage);
