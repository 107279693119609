import React, {useEffect, useRef} from "react";
import classNames from "classnames";
import {connect, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";

import Container from "@wisetack/shared-ui/components/Container";
import PageHeader from "@wisetack/shared-ui/components/PageHeader";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import image from "@wisetack/assets/img/bank_info.png";

import styles from "./LinkBankManualPage.module.scss";
import {checkRoutingNumber} from "../store/actions/merchantActions";
import RadioButton from "../components/RadioButton";
import {useHistory} from "react-router";
import {MERCHANT_MANUAL_ACCOUNT_DATA} from "../store/actions/types";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";

const validationSchema = Yup.object().shape({
    accountName: Yup.string().required('Please enter name on account'),
    routingNumber: Yup.string().required('Please enter routing number').matches(/(^\d{9}$)/, {message: 'Please enter a valid 9-digit routing number'}),
    accountNumber: Yup.string().required('Please enter account number').matches(/(^\d{4,17}$)/, {message: 'Please enter a valid account number'}),
    accountNumberCopy: Yup.string().required('Please re-enter account number').matches(/(^\d{4,17}$)/, {message: 'Please re-enter a valid account number'})
})

const accountTypes = ['Checking', 'Savings']
const pageName = 'Link Bank Manually';

const BankName = ({bankName, checking}) => {
    if (checking) {
        return <div className={styles.bankLabel}>checking...</div>
    }
    if (bankName) {
        return <div  className={styles.bankName}>{bankName}</div>
    }
    return null;
}

function LinkBankManualPage() {
    const history = useHistory();
    const dispatch = useDispatch();

    const manualAccountData = useSelector(state => state.merchant.manualAccountData);
    const merchantId = useSelector(state => state.merchant.id);

    const [bankName, setBankName] = React.useState(manualAccountData.bankName);
    const [checkingRoutingNumber, setCheckingRoutingNumber] = React.useState({});
    const [checkingAccountNumber, setCheckingAccountNumber] = React.useState({});

    useEffect(() => {
        logAmplitudeEvent(pageName, { merchantId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSave = (values) => {
        dispatch({type: MERCHANT_MANUAL_ACCOUNT_DATA, payload: {
            ...values,
            bankName
        }});
        history.push('/link_bank_review')
    }

    const formik = useFormik({
        initialValues: {
            accountName: manualAccountData.accountName || '',
            subtype: manualAccountData.subtype || accountTypes[0].toUpperCase(),
            routingNumber: manualAccountData.routingNumber || '',
            accountNumber: manualAccountData.accountNumber || '',
            accountNumberCopy: '',
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        validateOnMount: true,
        onSubmit: handleOnSave,
    });

    const rnMeta = formik.getFieldMeta("routingNumber") || {};
    const acMeta = formik.getFieldMeta("accountNumberCopy") || {};
    const accountNumber = formik.values['accountNumber'];
    const accountNumberCopy = formik.values['accountNumberCopy'];

    const setFieldError = useRef((field, message, skipLog) => {
        formik.setFieldError(field, message);
        formik.setFieldTouched(field, true, false);
        if (!skipLog) {
            logAmplitudeEvent(pageName, {error: "Validation Error", field, message});
        }
    })

    useEffect(
        () => {
            if (!rnMeta.error && rnMeta.value && rnMeta.value.length === 9) {
                if (rnMeta.value === checkingRoutingNumber.value) {
                    if (checkingRoutingNumber.errorMessage) {
                        setFieldError.current("routingNumber", checkingRoutingNumber.errorMessage, true);
                    }
                } else {
                    setCheckingRoutingNumber({inProgress: true, value: rnMeta.value});
                    checkRoutingNumber(rnMeta.value).then(data => {
                        let errorMessage
                        if (!data.bankName) {
                            errorMessage = "Bank name not found. Check routing number."
                            setFieldError.current("routingNumber", errorMessage);
                            setBankName('')
                        } else {
                            errorMessage = ''
                            setBankName(data.bankName);
                        }
                        setCheckingRoutingNumber({inProgress: false, value: rnMeta.value, errorMessage});
                    });
                }
            } else {
                setBankName('')
            }
        },
        [rnMeta.error, rnMeta.value, checkingRoutingNumber.errorMessage, checkingRoutingNumber.value],
    );

    useEffect(
        () => {
            if (acMeta.value && !acMeta.error && acMeta.value.length >= accountNumber.length) {
                let errorMessage
                if (acMeta.value !== accountNumber) {
                    errorMessage = "Not a match - please re-enter account number";
                    setFieldError.current("accountNumberCopy", errorMessage, !!checkingAccountNumber.errorMessage);
                }
                setCheckingAccountNumber({value: acMeta.value, errorMessage})
            }
        },
        [acMeta.error, acMeta.value, accountNumber, checkingAccountNumber.errorMessage],
    );

    const handleSubmit = () => {
        logAmplitudeEvent(pageName, {event: 'Pressed Continue Button'})
        formik.handleSubmit()
    }

    const handleCancel = () => {
        logAmplitudeEvent(pageName, {event: 'Pressed Cancel Button'})
        history.push('/business');
    }

    const getErrors = (data, fieldName) => {
        if (data.errorMessage && data.value === formik.values[fieldName]) {
            return {[fieldName]: data.errorMessage}
        }
        if (formik.touched[fieldName]) {
            return formik.errors
        }
        return null
    }

    const submitDisabled = checkingRoutingNumber.inProgress || !formik.isValid || accountNumber !== accountNumberCopy

    return <Container>
        <PageHeader  progress="80%">
            <div>
                Enter bank info
            </div>
            <div/>
        </PageHeader>
        <img className={styles.logo} src={image} alt="account-logo"/>
        <Form>
            <FormRow>
                <FormInput
                    type="text"
                    name="accountName"
                    label="Name on account"
                    formik={formik}
                    blacklist={/[^a-zA-Z0-9\s/'"&#=:;,.+_!%)(|-]/g}
                    autoFill="Account for testing"
                />
            </FormRow>
            <div className={styles.label}>Account type</div>
            <div className="row">
                {accountTypes.map(item => {
                    return (
                        <div className="col" key={item}>
                            <RadioButton
                                id={item}
                                label={item}
                                isSelected={formik.values['subtype'] === item.toUpperCase()}
                                value={item.toUpperCase()}
                                changed={(e) => { formik.setFieldValue('subtype', e.target.value) }}
                            />
                        </div>
                    )
                })}
            </div>
            <FormRow>
                <FormInput
                    name="routingNumber"
                    label="Routing number"
                    formik={formik}
                    errors={getErrors(checkingRoutingNumber, "routingNumber")}
                    blacklist={/[^0-9]/g}
                    pattern="[0-9]*"
                    autoFill="325272212"
                    max={9}
                />
            </FormRow>
            <BankName bankName={bankName} checking={checkingRoutingNumber.inProgress}/>
            <FormRow>
                <FormInput
                    name="accountNumber"
                    label="Account number"
                    formik={formik}
                    blacklist={/[^0-9]/g}
                    pattern="[0-9]*"
                    max={17}
                    autoFill="1111222233330000"
                    onCopy={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                />
            </FormRow>
            <FormRow>
                <FormInput
                    name="accountNumberCopy"
                    label="Re-enter account number"
                    formik={formik}
                    errors={getErrors(checkingAccountNumber, "accountNumberCopy")}
                    blacklist={/[^0-9]/g}
                    pattern="[0-9]*"
                    max={17}
                    autoFill="1111222233330000"
                    onCopy={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                    onPaste={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                />
            </FormRow>

            <button
                className={classNames("btn", "btn-block", submitDisabled ? styles.disabledButton : styles.continueButton)}
                type="button"
                onClick={ submitDisabled ? null : handleSubmit}
            >
                CONTINUE
            </button>
            <button className={classNames("btn", "btn-block", styles.cancelButton)} onClick={handleCancel}>
                CANCEL
            </button>
        </Form>
    </Container>
}

export default connect(null)(
    LinkBankManualPage
);

