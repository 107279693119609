import React from "react";

import styles from "./PrequalList.module.scss";
import Prequal from "./Prequal";

const PrequalList = ({prequals, onPrequalClick}) => {
    return (
        <div className={styles.prequals}>
            {prequals && prequals.map(prequal => {
                return (
                    <Prequal item={prequal} key={prequal.id}
                                 onClick={onPrequalClick}/>
                )
            })}
        </div>
    )
}

export default PrequalList;