import React from "react";
import Button from "./Button";
import styles from "./ButtonFilled.module.scss"

function ButtonFilled({ children, disabled, onClick}) {

    const filledButtonClasses = {
        [styles.enabled]: !disabled,
        [styles.disabled]: disabled,
    };

    return (
        <Button customClasses={filledButtonClasses} disabled={disabled} onClick={onClick}>
            {children}
        </Button>
    )
}

export default ButtonFilled;
