import amplitude from "amplitude-js/amplitude";
export var initAmplitude = function initAmplitude(key) {
  amplitude.getInstance().init(key);
};
export var setAmplitudeUserId = function setAmplitudeUserId(userId) {
  amplitude.getInstance().setUserId(userId);
};
export var logAmplitudeEvent = function logAmplitudeEvent(eventType, eventProperties) {
  amplitude.getInstance().logEvent(eventType, eventProperties);
};
export var setAmplitudeUserProperty = function setAmplitudeUserProperty(key, value) {
  var identify = new amplitude.Identify().set(key, value);
  amplitude.getInstance().identify(identify);
};