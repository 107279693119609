import React from "react"
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import Container from "../components/Container";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import image from "@wisetack/assets/img/question-mark.svg";

import {
    setError
} from "../store/actions/merchantActions";
import { appTransition } from "../utils/transitions"

import styles from "./HelpPage.module.scss";
import NavBar from "../components/NavBar";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "newTransaction"
]

const pageName = "Help Page"
const logProps = { page: pageName }

class HelpPage extends React.Component {
    state = {
        errorMessage: "",
        userChanged: false,
        userId: null
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
        }
        logProps.merchantId = merchantId
        logAmplitudeEvent(pageName, logProps);
    }

    componentDidUpdate(prevProps) { }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias
        });
        this.props.setError(null)
        appTransition(e, this.props)
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `HelpPage state/props`);

        return (
            <Container>
                <NavBar title={"Help"}
                    users={this.state.users}
                    onMenuItemClick={this.handleMenuItemClick} />
                <div className={styles.title}>Need help?</div>
                <img className={styles.image} src={image} alt="bank-logo" />
                <div className={styles.subtitle}>
                    <p>
                        We would be happy to help! If you have a question please text or call us at <br/><a href="tel:1-833-927-0333">1-833-927-0333</a>.
                    </p>
                    <p>
                        Email us at <a href="mailto:support@wisetack.com">support@wisetack.com</a> or check out our <a href="https://www.wisetack.com/faqs" rel="noopener noreferrer" target="_blank">FAQs</a>.
                    </p>
                </div>
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        setError
    }
)(HelpPage);
