import React from "react";
import classNames from "classnames";
import percentage from "../assets/img/percentage.svg"

import styles from "./PrequalOfferTerms.module.scss";
import {getOfferInfo} from "../utils/offerInfo";

export function PrequalOfferTerms({ offerTerms }) {
    if (!offerTerms) {
        return null
    }

    const maxAmount = !!offerTerms.maxAmount ? `Max. qualified amount: ${offerTerms.maxAmount}` : ''
    const offers = getOfferInfo(offerTerms)

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse show": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    aria-expanded="true"
                    href="#collapseOfferTerms"
                >
                    <div className={styles.header}>
                        Offer terms
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseOfferTerms" className={cardBodyClasses}>
                <div className={styles.info}>
                    <span className="material-icons-outlined">paid</span>
                    {maxAmount}
                </div>
                <div className={classNames(styles.descrWrapper)}>
                    {offers && offers.terms && <div className={classNames(styles.termsIcon, styles.info)}>
                        <img src={percentage} alt="aprIcon"/>
                    </div>}
                    {offers && offers.terms && <div className={classNames(styles.terms, styles.info)}>Offers available</div>}
                    {offers && offers.terms && <div className={classNames(styles.termsValue, styles.info)}>{offers.terms.apr}<br/>{offers.terms.months}</div>}

                    {offers && <div className={classNames(styles.mdrsIcon, styles.info)}>
                        <span className="material-icons-outlined">credit_card</span>
                    </div>}
                    {offers && <div className={classNames(styles.mdrs, styles.info)}>Processing fee</div>}
                    {offers && <div className={classNames(styles.mdrsValue, styles.info)}>{offers.mdrs}</div>}
                </div>
            </div>
        </div>
    )
}
