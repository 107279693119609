import {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router";
import {useDispatch, useSelector} from "react-redux";

import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";

import {appTransition} from "../utils/transitions";
import {stateFromProps} from "../utils/state";
import {
    API_ERROR_RESPONSE,
    MERCHANT_LOG_OUT,
    MERCHANT_SET_USER
} from "../store/actions/types";

const arrangeUsers = (users, user) => {
    return stateFromProps({users, user}, {}, {}).users
}

export default function useAppFlow({getMerchant, newRequest} = {}, pageName) {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch()

    const user = useSelector(state => state.merchant.user);
    const merchant = useSelector(state => state.merchant.merchant);
    const users = arrangeUsers(useSelector(state => state.merchant.users), user);
    const merchantId = merchant ? merchant.id : localStorage.getItem("merchant:id");
    const userId = user ? user.userId : null;

    const [authMerchant, setAuthMerchant] = useState();

    const logAmplitude = (eventType, eventProperties) => {
        let evProps = {
            page: pageName,
            merchantId,
            userId,
            path: location.pathname
        }
        if (eventProperties) {
            evProps = {
                ...evProps,
                ...eventProperties
            }
        }
        if (!eventType) {
            eventType = evProps.page
        }
        logAmplitudeEvent(eventType, evProps);
    }

    useEffect(() => {
        if (pageName && userId) {
            logAmplitude()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageName, userId])

    useEffect(() => {
        if (location.pathname === "/prequals") {
            // temporary enable to get prequals without auth TODO: remove after testing
            // usage example:
            // http://localhost:3000/#/prequals?id=b3eb7958-f606-433f-90bb-289e5ff8c805
            const id = new URLSearchParams(location.search).get('id')
            if (id) {
                console.log(`Getting prequals for merchant [${id}]`)
                setAuthMerchant({id})
                return
            }
        }
        if (!merchantId || !sessionStorage.getItem("wisetack:ba:token")) {
            location.pathname !== "/" && history.push("/")
            return
        }
        if (!merchant && location.pathname === '/prequal') {
            history.push("/prequals")
            return
        }
        if (!merchant && getMerchant) {
            getMerchant(merchantId, newRequest ? newRequest() : null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchant, merchantId])

    const setError = (payload) => {
        dispatch({ type: API_ERROR_RESPONSE, payload });
    }

    const logOut = (payload) => {
        dispatch({ type: MERCHANT_LOG_OUT, payload });
    }

    const setMerchantUser = (user) => {
        dispatch({ type: MERCHANT_SET_USER, payload: user });
    }

    const handleMenuItemClick = (e) => {
        setError(null)
        appTransition(e, {
            user,
            history,
            location,
            setMerchantUser,
            setError,
            logOut
        })
    }

    return {merchant: merchant || authMerchant, skipAuth: !!authMerchant, user, users, handleMenuItemClick, logAmplitude}
}