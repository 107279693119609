import React from "react"
import classNames from "classnames";
import {connect} from "react-redux";
import faker from "faker/locale/en_US";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import Form from "@wisetack/shared-ui/components/Form";
import FormRow from "@wisetack/shared-ui/components/FormRow";
import FormInput from "@wisetack/shared-ui/components/FormInput";
import FormNumberInput from "@wisetack/shared-ui/components/FormNumberInput";
import FormInputResizableTextArea from "@wisetack/shared-ui/components/FormInputResizableTextArea";
import FormDatepickerFlatpickrInput from "@wisetack/shared-ui/components/FormDatepickerFlatpickrInput";

import {MerchantFieldValidator} from "@wisetack/shared-ui/utils/MerchantFieldValidator";

import {formatUsPhone} from "@wisetack/shared-ui/utils/format";
import {logAmplitudeEvent} from "@wisetack/shared-ui/components/Amplitude";
import {
    createTransaction,
    getMerchant,
    getVerticalMessages,
    logOut,
    setError,
    setMerchantUser
} from "../store/actions/merchantActions";
import {appTransition} from "../utils/transitions"

import styles from "./NewTransactionPage.module.scss";
import {stateFromProps} from "../utils/state";
import moment from "moment";
import {isNewTransactionAvailable} from "@wisetack/shared-ui/utils/transactions";
import JobIdInfoModal from "./JobIdInfoModal";
import NavBar from "../components/NavBar";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "prequal",
    "createTransactionRequestId",
    "jwt"
]

const formFields = {
    mobileNumber: () => {
        return {
            label: 'Customer Mobile Number',
            inputMode: 'numeric',
            pattern: '[0-9]*',
            autoFill: '5555555555',
            autoFormat: formatUsPhone,
            validatorName: 'validateMobileNumber'
        };
    },
    transactionAmount: (transactionLimitMax, transactionLimitMin) => {
        return {
            label: 'Loan Amount',
            validatorName: 'validateTransactionAmount',
            type: 'number',
            inputMode: 'decimal',
            pattern: '[0-9]*',
            autoFill: () => faker.random.number({ min: transactionLimitMin, max: transactionLimitMax }).toString(),
            blacklist: /^0+/g,
            additionalContentBelow: () => {
                return (
                    <p className={styles.transactionAmountHint}>
                        Between ${transactionLimitMin.toCommaSeparatedMoney()} and ${transactionLimitMax.toCommaSeparatedMoney()}
                    </p>
                );
            }
        };
    },
    serviceCompletedOn: (label) => {
        return {
            label: label,
            type: 'date'
        };
    },
    jobId: (label, jobInfoLabel) => {
        return {
            label: label,
            optional: true,
            validatorName: 'validateJobId',
            autoFill: 'invoice_123',
            additionalContentBelow: () => {
                return (
                    <>
                        <p className={styles.rateInfo}
                           data-toggle="modal"
                           data-target="#jobIdInfoModal">
                        <span
                            className="material-icons"
                            onClick={() => logAmplitudeEvent('Pressed Job Info link', {})}
                        >
                            info_outline
                        </span>
                            {jobInfoLabel}
                        </p>
                    </>
                );
            }
        };
    },
    transactionPurpose: () => {
        return {
            label: 'Service Description',
            validatorName: 'validateLoanPurpose',
            autoFill: () => faker.commerce.productName(),
            type: 'textArea'
        };
    }
}
let renderedFormFields = {};

const pageName = "New Transaction Page"
const logProps = {page: pageName}

class NewTransactionPage extends React.Component {
    state = {
        mobileNumber: "",
        transactionAmount: "",
        serviceCompletedOn: null,
        transactionPurpose: "",
        fieldsChanged: {},
        errorMessage: "",
        userChanged: false,
        userId: null,
        jobId: null,
        verticalMessagesLoading: false
    }

    constructor(props) {
        super(props);
        this.validator = new MerchantFieldValidator(pageName);
    }

    getEditValue = name => {
        if (this.state.fieldsChanged[name] || this.state[name]) {
            return this.state[name];
        }
        if (this.props.fieldsValue[name]) {
            return this.props.fieldsValue[name];
        }
        return "";
    };

    componentDidMount() {
        if (!sessionStorage.getItem("wisetack:ba:token")) {
            Log.error('Token not found.')
            this.props.history.push("/");
            return;
        }
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
            if (!!this.props.merchant) {
                this.setState({
                    verticalMessagesLoading: true
                })
                this.props.getVerticalMessages(this.props.merchant.vertical, 'portal-new-transaction-page');
            }
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId
        if (this.props.user) {
            logProps.userId = this.props.user.userId
        }
        logAmplitudeEvent(pageName, logProps);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps);
    }

    componentDidUpdate(prevProps) {
        if (!!this.props.merchant && !this.props.messages && !this.state.verticalMessagesLoading) {
            this.setState({
                verticalMessagesLoading: true
            })
            this.props.getVerticalMessages(this.props.merchant.vertical, 'portal-new-transaction-page');
        }
        if (this.props.createTransactionRequestId && this.props.createTransactionRequestId !== prevProps.createTransactionRequestId) {
            if (this.props.errorMessage) {
                // on error stay on this page
                return;
            }
            this.props.history.push("/transaction_review");
        }
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias 
        });
        this.props.setError(null)
        if (e.alias !== 'new_transaction') {
            appTransition(e, this.props)
        }
    }

    setError(name, error) {
        this.setState({
            errors: {
                ...this.state.errors,
                [name]: error
            }
        });
    }

    autoFill = (name, val) => {
        if (val === "~" && renderedFormFields[name].autoFill) {
            if (name === 'mobileNumber') {
                setTimeout(() => {
                    if(renderedFormFields.transactionAmount.innerRef.current) {
                        renderedFormFields.transactionAmount.innerRef.current.focus();
                    }
                }, 500);
            }
            if (name === 'transactionAmount') {
                setTimeout(() => {
                    if (renderedFormFields.serviceCompletedOn.innerRef.current) {
                        renderedFormFields.serviceCompletedOn.innerRef.current.focus();
                    }
                }, 500);
            }
            if (typeof renderedFormFields[name].autoFill === "function") {
                return renderedFormFields[name].autoFill();
            }
            return renderedFormFields[name].autoFill;
        }
        return val;
    };

    autoFormat = (name, val) => {
        if (!!val && renderedFormFields[name] && typeof renderedFormFields[name].autoFormat === "function") {
            return renderedFormFields[name].autoFormat(val);
        }
        return val;
    }

    inputCleanUp = (name, val) => {
        if (val && renderedFormFields[name] && renderedFormFields[name].blacklist) {
            return val.replace(renderedFormFields[name].blacklist, "");
        }
        return val;
    }

    handleOnChange = e => {
        if (this.props.jwt && this.props.jwt.exp && new Date() >= this.props.jwt.exp) {
            Log.info(`JWT expired at ${this.props.jwt.exp}.`);
            this.props.history.push("/");
            return;
        }
        let val = e.target.value;
        let name = e.target.name;
        val = this.autoFill(name, val);
        val = this.inputCleanUp(name, val);
        val = this.autoFormat(name, val);
        this.setState({
            [name]: val,
            fieldsChanged: { ...this.state.fieldsChanged, [name]: true }
        });
        this.setError(name, "");
    };

    handleOnBlur = e => {
        let val = e.target.value;
        let name = e.target.name;
        const error = this.validateField(name, val);
        this.setError(name, error);
    };

    validateField = (name, val) => {
        if (typeof renderedFormFields[name].validator === "function") {
            if (renderedFormFields[name].suggestFn) {
                return renderedFormFields[name].validator(val, renderedFormFields[name].suggestFn.bind(this), this.props);
            } else {
                return renderedFormFields[name].validator(val, null, this.props);
            }
        }
        if (!val && !renderedFormFields[name].optional) {
            const message = renderedFormFields[name].mandatory || `Enter ${renderedFormFields[name].label}`
            this.validator.log(renderedFormFields[name].label, message);
            return message;
        }
        return "";
    };

    formatMobileNumber = mobileNumber => {
        mobileNumber = mobileNumber.replace(/\D/g, "");
        if (mobileNumber.length === 10) {
            return "+1" + mobileNumber;
        } else if (mobileNumber.length === 11) {
            return "+" + mobileNumber;
        }
        return mobileNumber;
    };

    handleSubmit = () => {
        if (!this.props.user) {
            this.props.history.push("/");
            return;
        }
        let data = {
            merchantId: this.props.user.merchantId,
            userId: this.props.user.userId,
            mobileNumber: this.formatMobileNumber(this.state.mobileNumber),
            transactionPurpose: this.state.transactionPurpose,
            serviceCompletedOn: this.formatServiceCompletedOn(this.state.serviceCompletedOn),
            transactionAmount: this.state.transactionAmount.replace(/[$,]/g,''),
            portalFlow: true,
            purchaseId: this.state.jobId
        }
        logAmplitudeEvent("Send Text Message button clicked", logProps)
        this.props.createTransaction(data)
    }

    formatServiceCompletedOn = (date) => {
        return  moment(date).format("YYYY-MM-DD");
    }

    isButtonDisabled = () => {
        if (this.props.isLoading || !this.props.messages) return true;
        for (const key in this.state.errors) {
            if (this.state.errors[key]) return true;
        }
        if (Object.entries(renderedFormFields).length < 1) {
            return true;
        }
        for (const [name, value] of Object.entries(renderedFormFields)) {
            if (!value.optional) {
                if (!this.state[name]) {
                    return true;
                }
            }
        }
        return false;
    };

    render() {
        Log.info({ state: this.state, props: this.props }, `NewTransactionPage state/props`);
        if (!isNewTransactionAvailable(this.props.user)) {
            return null;
        }

        const HeaderContent = () => {
            return (
                <>
                    <NavBar title={"New Transaction"}
                            users={this.state.users}
                            onMenuItemClick={this.handleMenuItemClick}
                    />
                    <div className={styles.title}>We’ll text the customer a <br/> link to apply</div>
                </>
            )
        };

        let btnDisabled = this.isButtonDisabled();

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            "btn-disabled": btnDisabled,
            [styles.buttonDisabled]: btnDisabled,
            [styles.buttonEnabled]: !btnDisabled
        });

        const formInput = (fieldName) => {
            if (renderedFormFields[fieldName].type === 'date') {
                return (
                    <FormDatepickerFlatpickrInput
                        name={fieldName}
                        completedOnDate = {this.state.serviceCompletedOn}
                        label={renderedFormFields[fieldName].label}
                        onChange={this.handleOnChange}
                        onBlur={() => {
                            const value = this.getEditValue("serviceCompletedOn");
                            let error = "Please enter valid date"
                            if (!!value){
                                error = "";
                            }
                            if(error) {
                                Log.error(error, `date input validation`)
                            }
                            this.setError(fieldName, error);
                        }}
                        nextInput={renderedFormFields.jobId.innerRef}
                        errorMessage = { this.state.errors ? this.state.errors[fieldName] : null}
                        fieldsError={this.props.fieldsError}
                        skipValidation={true}
                    />
                )
            }
            if (renderedFormFields[fieldName].type === 'number') {
                return (
                    <FormNumberInput
                        name={fieldName}
                        label={renderedFormFields[fieldName].label}
                        suffix={renderedFormFields[fieldName].suffix}
                        value={this.getEditValue(fieldName)}
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnBlur}
                        errors={this.state.errors}
                        innerRef={renderedFormFields[fieldName].innerRef}
                        inputMode={renderedFormFields[fieldName].inputMode}
                        pattern={renderedFormFields[fieldName].pattern}
                        fieldsError={this.props.fieldsError}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        allowNegative={false}
                        additionalContentBelow={renderedFormFields[fieldName].additionalContentBelow}
                    />
                )
            }
            if (renderedFormFields[fieldName].type === 'textArea') {
                return (
                    <FormInputResizableTextArea
                        name={fieldName}
                        type='text'
                        label={renderedFormFields[fieldName].label}
                        value={this.getEditValue(fieldName)}
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnBlur}
                        errors={this.state.errors}
                        innerRef={renderedFormFields[fieldName].innerRef}
                        textareaLineHeight={24}
                        minRows={1}
                        maxRows={5}
                  />
                )
            }
            return (
                <>
                    <FormInput
                        name={fieldName}
                        label={renderedFormFields[fieldName].label}
                        value={this.getEditValue(fieldName)}
                        onChange={this.handleOnChange}
                        onBlur={this.handleOnBlur}
                        errors={this.state.errors}
                        inputMode={renderedFormFields[fieldName].inputMode}
                        pattern={renderedFormFields[fieldName].pattern}
                        innerRef={renderedFormFields[fieldName].innerRef}
                        fieldsError={this.state.fieldsError}
                        additionalContentBelow={renderedFormFields[fieldName].additionalContentBelow}
                    />
                </>
            )
        }

        const isLoading = this.props.isLoading || !this.props.messages;
        if (!isLoading) {
            let max = this.props.merchant.transactionLimit.max;
            let min = this.props.merchant.transactionLimit.min;
            renderedFormFields = {
                mobileNumber: formFields.mobileNumber(),
                transactionAmount: formFields.transactionAmount(max, min),
                serviceCompletedOn: formFields.serviceCompletedOn(this.props.messages['form.service_date']),
                jobId: formFields.jobId(this.props.messages['form.job_id'], this.props.messages['form.job_id.below']),
                transactionPurpose: formFields.transactionPurpose()
            };
            for (const value of Object.values(renderedFormFields)) {
                if (value.validatorName) {
                    value.validator = this.validator[value.validatorName];
                }
                value.innerRef = React.createRef();
            }
        }

        return (
            <Container>
                <HeaderContent />
                {!isLoading && (
                    <>
                        <Form>
                            <FormRow>
                                {formInput("mobileNumber")}
                            </FormRow>
                            <FormRow>
                                {formInput("transactionAmount")}
                            </FormRow>
                            <FormRow>
                                {formInput("serviceCompletedOn")}
                            </FormRow>
                            <FormRow>
                                {formInput("jobId")}
                            </FormRow>
                            <FormRow>
                                {formInput("transactionPurpose")}
                            </FormRow>
                        </Form>
                        <div className="row" style={{paddingBottom: "20px"}}/>
                        <Error pageName={pageName}>{this.state.errorMessage}</Error>
                        <div className="row" style={{paddingBottom: "25px"}}>
                            <div className="col">
                                <button className={btnClasses} onClick={this.handleSubmit}>
                                    SEND TEXT MESSAGE
                                </button>
                            </div>
                        </div>
                        <JobIdInfoModal messages={this.props.messages}/>
                    </>
                )}
                <LoaderWithMessage loading={isLoading} />
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {},
        messages: state.merchant.messages
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { 
        getMerchant, 
        setMerchantUser,
        createTransaction, 
        logOut,
        setError,
        getVerticalMessages
    }
)(NewTransactionPage);
