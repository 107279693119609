import {
    logValidationError,
    validateEmail,
    validateMobileNumberInDomesticFormat,
    validateSafeTextFirstName,
    validateSafeTextLastName
} from "@wisetack/shared-ui/src/utils/FieldValidator";
import {emailSuggester} from "@wisetack/shared-ui/src/utils/FieldSuggester";

export class UserFieldValidator {

    constructor(page, props) {
        this.application = "MerchantPortal";
        this._props = props;
        this.page = page;
        this.setValidators();
    }

    set props(val) {
        this._props = val;
        this.setValidators();
    }

    get props() {
        return this._props;
    }

    setValidator(validateFn, field, suggestFn) {
        return logValidationError(validateFn, this.application, this.page, field, this._props, suggestFn);
    }

    setValidators() {
        this.validateMobileNumber = this.setValidator(validateMobileNumberInDomesticFormat, "Mobile Phone");
        this.validateEmail = this.setValidator(validateEmail, "Email Address", emailSuggester);
        this.validateFirstName = this.setValidator(validateSafeTextFirstName, "First Name");
        this.validateLastName = this.setValidator(validateSafeTextLastName, "Last Name");
    }

    log(field, message) {
        logValidationError((props) => props, this.application, this.page, field, this._props)(message);
    }
}