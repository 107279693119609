import React, {useEffect, useState} from "react";
import classNames from "classnames";

import styles from "./DropdownMultiSelect.module.scss";

const SelectItem = ({groupId, itemId, item, onClick}) => {
    let icon
    if (item.selected) {
        icon = "check_box"
    } else {
        icon = "check_box_outline_blank"
    }
    let text = item.description ? item.description : item.text
    return (
        <div className={classNames("row", styles.selectItem)} onClick={() => onClick(groupId, itemId, item)} >
            <div className={classNames("col", styles.box)}>
                        <span className={classNames({"material-icons-outlined": true, [styles.selected]: item.selected})}>
                            {icon}
                        </span>
            </div>
            <div className={classNames("col", styles.text)}>{text}</div>
        </div>
    )
}

const DropdownMultiSelect = ({id, caption, filters, onSelectItemClick}) => {

    const [value, setValue] = useState("")
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        let val = ""
        Object.values(filters).forEach(item => {
            if (item.selected) {
                if (val) {
                    val = val + ", "
                }
                val = val + item.text
            }
        })
        if (!val) {
            val = "All " + id
        }
        setValue(val)
    }, [filters, id])

    const arrowClasses = classNames({
        "material-icons": true,
        [styles.expanded]: opened
    });

    return (
        <div className={styles.filter}>
            <div className={styles.label}>
                {caption}
            </div>
            <div className={styles.form}
                 onClick={() => setOpened(!opened)}>
                <div className={styles.title}>
                    {value}
                </div>
                <div className={styles.toggle}>
                        <span>
                            <i className={arrowClasses}>
                                   keyboard_arrow_down
                            </i>
                        </span>
                </div>
            </div>
            {opened &&
            <div className={styles.options}>
                {Object.entries(filters).map( ([key, value]) => {
                    return (
                        <SelectItem key={key} groupId={id} itemId={key} item={value} onClick={onSelectItemClick}/>
                    )
                })}
            </div>
            }
        </div>
    )
}

export default DropdownMultiSelect;
