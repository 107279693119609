import React from "react";
import downloadicon from "../assets/img/downloadicon.png";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { exportTransactions, exportPrequals } from "../store/actions/merchantActions";
import { connect} from "react-redux";
import uuid from "uuid";
import loadingIcon from "../assets/img/loading.svg";

const eventType = "Export To CSV";
const logProps = {event: 'Export CSV'};
const stateNames = [    
    "errorMessage",
    "apiRequests",
    "merchant",
    "exportedTransactions",
    "exportedPrequals",
    "filter",
    "prequalFilter"
]

const TRANSACTION_FIELDS = {'Loan ID' : 'id', 'Job ID' : 'jobId', 'Initiator User Name' : initiatorUserName, 'Initiated' : initiatedDate, 'Consumer' : consumerName, 'Consumer Email' : consumerEmail, 'Consumer Phone' : consumerPhone,  
'Status' : 'status', 'Approved Date' : approvedDate, 'Approved Amount' : 'approvedLoanAmount', 'Settled Date' : settledDate, 'Net Proceeds' : 'settledLoanAmount'}

const PREQUALS_FIELDS = {'Prequal ID' : getShortId, 'Initiator User Name' : initiatorUserName, 'Initiated' : transformTime, 'Consumer Name' : consumerInfoName,  'Consumer Email' : consumerInfoEmail, 'Consumer Phone' : consumerInfoPhone,  
                'Status' : 'status', 'Approved Date' : transformTime, 'Approved Ammount' : approvedAmmount };

const TZ_OFFSET_REGEX = /[-+]\d{4}$/;

function approvedDate(tx){
    let approvedDate = '';
    tx.statusHistory.forEach(e => { 
        if (e.status === 'Confirmed')
            approvedDate = e.date.replace('T', ' ').replace(TZ_OFFSET_REGEX, '');
    });
    return approvedDate;
}

function initiatedDate(tx){
    return tx.initiatedDate.replace('T', ' ').replace(TZ_OFFSET_REGEX, '');
}

function consumerName(tx){
    return tx.consumer.fullName;
}

function consumerPhone(tx){
    return tx.consumer.phone;
}

function consumerEmail(tx){
    return tx.consumer.email;
}

function consumerInfoName(tx){
    return tx.customerInformation.fullName;
}

function consumerInfoPhone(tx){
    return tx.customerInformation.phone;
}

function consumerInfoEmail(tx){
    return tx.customerInformation.email;
}

function settledDate(tx){
    let settledDate = '';
    tx.statusHistory.forEach(e => { 
        if (e.status === 'Settled')
            settledDate = e.date.replace('T', ' ').replace(TZ_OFFSET_REGEX, '');
    });
    return settledDate;
}

function getShortId(tx){
    const SHORT_ID_LENGTH = 8;

    return tx.id.substring(0,SHORT_ID_LENGTH);
}

function transformTime(tx){

    function put0(number){
        return number > 9 ? number : '0' + number; 
    }

    const formattedDate = new Date(tx.createdAt);
    return formattedDate.getFullYear() + '-' + put0(formattedDate.getMonth() + 1) + '-' + put0(formattedDate.getDate()) + ' ' 
        + put0(formattedDate.getHours()) + ':' + put0(formattedDate.getMinutes()) + ':' + put0(formattedDate.getSeconds());  
}

function approvedAmmount(tx){
    return tx.offerTerms ? tx.offerTerms.maxAmount.replace(',', '') : '';
}

function initiatorUserName(tx){
    if (tx.initiatorUserName === 'null' || tx.initiatorUserName === 'null null')
        return '';
    
    return tx.initiatorUserName;
}


class TransactionExport extends React.Component {

    constructor(props){
        super(props);
        this.requestId = "";
        this.state = {isLoading : false};
        this.fieldsToMap = TRANSACTION_FIELDS;
        if (this.props.trxType === "PREQUALIFICATIONS")
            this.fieldsToMap = PREQUALS_FIELDS;
    }

    componentDidUpdate(prevProps) {
        if(!!this.props.exportedTransactions && prevProps.exportedTransactions !== this.props.exportedTransactions) {
            this.exportData(this.mapToCsv(this.props.exportedTransactions));
            logAmplitudeEvent(eventType, logProps);
            this.setState({isLoading : false});
        }
        if(!!this.props.exportedPrequals && prevProps.exportedPrequals !== this.props.exportedPrequals) {
            this.exportData(this.mapToCsv(this.props.exportedPrequals));
            logAmplitudeEvent(eventType, logProps);
            this.setState({isLoading : false});
        }
    }

    onExportClick = () => {
        this.requestId = uuid.v4(); 
        if (this.props.trxType === "PREQUALIFICATIONS"){ 
            this.props.exportPrequals(this.props.merchant.id, this.props.prequalFilter, this.requestId);
        }
        else {
            this.props.exportTransactions(this.props.merchant.id, this.props.filter, this.requestId);
        }
        this.setState({isLoading : true});      
    }

    mapFields = (row) => {

        let fieldsMapped = [];
        const fieldsToMap = this.fieldsToMap; 
        for (var prop in fieldsToMap){
            if(fieldsToMap[prop] && typeof fieldsToMap[prop] === 'function'){
                fieldsMapped.push(fieldsToMap[prop](row));
            }
            else if (fieldsToMap[prop] && typeof fieldsToMap[prop] !== 'function' && row[fieldsToMap[prop]]){
                fieldsMapped.push(row[fieldsToMap[prop]].replace(',', ''));    
            }
            else{
                fieldsMapped.push("");
            }
        }   
        return fieldsMapped;
    }

    mapToCsv = (jsonArray) => {
        const fieldsToMap = this.fieldsToMap; 
        let str = "";
        const columns = Object.keys(fieldsToMap);

        jsonArray.forEach((e, index) => {
            let mappedRow = this.mapFields(e);
            if (index === 0)
                str += columns.join(',') + '\r\n';
            str += mappedRow.join(',');
            if (index < jsonArray.length - 1)
                str += '\r\n';    
        });
        return str;
    }
    
    exportData = (data) => {

        const date = new Date();
        const month = date.getUTCMonth() + 1; 
        const day = date.getUTCDate();
        const year = date.getUTCFullYear();
    
        const exportedFilenmae = (month < 10 ? 0 + "" + month : month)  + "-" + (day < 10 ? 0 + "" + day : day)  + "-" + year + '.csv';
    
        const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        let link = document.createElement("a");
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    render() {
        const imgStyle = {
            cursor: 'pointer',
            width : '28px',
            height : '28px',
            marginLeft : '15px',

        };
        const loadingStyle = {
            ...imgStyle,
            cursor : 'default',
        };
        let requestInProgress = !!this.requestId && !!this.props.apiRequests[this.requestId];
        return (
            <span>
            {(!this.state.isLoading || !requestInProgress) && <img src={downloadicon} style={imgStyle} alt="Download" onClick={this.onExportClick}/>}  
            {this.state.isLoading && requestInProgress && <img src={loadingIcon} style={loadingStyle} alt="Loading"/>}
            </span>
        );
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {

    let props = {};
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { 
        exportTransactions,
        exportPrequals
    }
)(TransactionExport);