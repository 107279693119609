import React from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";

export function ServiceDescription({ item }) {
    const transactionPurpose = item.transactionPurpose

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse show": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    aria-expanded="true"
                    href="#collapseServiceDescription"
                >
                    <div className={styles.header}>
                        Service description
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseServiceDescription" className={cardBodyClasses}>
                <div className={styles.info}>
                    {transactionPurpose}
                </div>
            </div>
        </div>
    )
}
