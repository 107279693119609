import React from "react"
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import { Loader } from "../components/Loader"
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import NavBar from "../components/NavBar";
import TransactionsControl from "../components/TransactionsControl";
import failIceCreamImage from "@wisetack/assets/img/fail-ice-cream.svg";
import plane from "@wisetack/assets/img/paper-plane.svg";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import { 
    getTransactions, 
    getMerchant, 
    setTransactionData,
    setMerchantUser, 
    logOut,
    setError 
} from "../store/actions/merchantActions";
import { countSelected } from "../utils/format"
import TransactionList  from "../components/TransactionList"
import { appTransition } from "../utils/transitions"
import { transactionsFilter } from "../utils/format"
import { stateFromProps } from "../utils/state"

import styles from "./TransactionsPage.module.scss";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "transactions",
    "filter",
    "filterId",
    "cursorAfter"
]

const pageName = "Merchant Transactions Page"
const logProps = {page: pageName}

class TransactionsPage extends React.Component {
    state = {
        errorMessage: "",
        userChanged: false,
        userId: null
    }

    componentDidMount() {
        if (!sessionStorage.getItem("wisetack:ba:token")) {
            Log.error('Token not found.')
            this.props.history.push("/");
            return;
        }
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId;
        if (this.props.user) {
            logProps.userId = this.props.user.userId;
        }
        logAmplitudeEvent(pageName, logProps);
        if (!this.props.transactions) {
            logAmplitudeEvent("Get transactions", {
                ...logProps,
                filter: transactionsFilter(this.props.filter)
            });
            this.props.getTransactions(merchantId, this.props.filter);
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps) {
        let updateTransactions = false
        if (prevProps.user && this.props.user && prevProps.user.merchantId !== this.props.user.merchantId) {
            updateTransactions = true;
            logProps.merchantId = this.props.user.merchantId;
            logAmplitudeEvent("Get transactions on merchant changed", {
                ...logProps,
                filter: transactionsFilter(this.props.filter) 
            });
        }
        if (this.props.filterId && this.props.filterId !== prevProps.filterId) {
            updateTransactions = true;
            logAmplitudeEvent("Get transactions on filter changed", {
                ...logProps,
                filter: transactionsFilter(this.props.filter) 
            });
        }
        if (updateTransactions) {
            this.props.getTransactions(this.props.user.merchantId, this.props.filter);
        }
    }

    handleTransactionClick = (item) => {
        Log.info(`Transaction [${item.id}] clicked.`)
        this.props.setTransactionData(item);
        this.props.history.push("/transaction");
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias 
        });
        this.props.setError(null)
        if (e.alias !== 'transaction_history') {
            appTransition(e, this.props)
        }
    }

    handleFilterClick = () => {
        this.props.history.push("/filters");
    }

    handleCreateClick = () => {
        this.props.history.push("/new_transaction");
    }

    handleNextPageClick = () => {
        logAmplitudeEvent("Get transactions for next page", {
            ...logProps,
            filter: transactionsFilter(this.props.filter),
            cursorAfter: this.props.cursorAfter
        });
        this.props.getTransactions(this.props.user.merchantId, this.props.filter, this.props.cursorAfter);
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `TransactionsPage state/props`);
        
        const filterSelections = countSelected(this.props.filter)

        const NextPageButton = () => {
            if (!this.props.cursorAfter) {
                return null;
            }

            return (
                <div className={styles.nextPage} onClick={this.handleNextPageClick}>
                    See more transactions
                    <i className="material-icons">keyboard_arrow_down</i>
                </div>
            )
        }

        function noTransactionsInfoBasedOnFilter() {
            return (
                <div>
                    <img className={styles.noTransactionsImage} src={failIceCreamImage} alt="error-logo" />
                    <div className={styles.noTransactions}>No results</div>
                    <div className={styles.noTransactionsHint}>Try to adjust your filters</div>
                </div>
            );
        }

        function noTransactionsInfo() {
            return (
                <div>
                    <img className={styles.noTransactionsImage} src={plane} alt="error-logo" />
                    <div className={styles.noTransactions}>No transactions yet</div>
                    <div className={styles.noTransactionsHint}>All your new transactions will appear here</div>
                </div>
            );
        }

        return (
            <Container>
                <NavBar title={"Transactions"} users={this.state.users} onMenuItemClick={this.handleMenuItemClick}/>
                <div className={styles.subtitle}>Loan applications</div>
                <TransactionsControl user={this.props.user}
                                     selectedFiltersCount={filterSelections}
                                     handleFilterClick={this.handleFilterClick}
                                     handleCreateClick={this.handleCreateClick}
                                     transactions={this.props.transactions}
                                     filter={this.props.filter}
                                     trxType={"TRANSACTIONS"}/>  
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                <TransactionList transactions={this.props.transactions} handleTransactionClick={this.handleTransactionClick}/>
                {this.props.transactions && this.props.transactions.length === 0 && filterSelections > 0 && noTransactionsInfoBasedOnFilter()}
                {this.props.transactions && this.props.transactions.length === 0 && filterSelections === 0 && noTransactionsInfo()}
                {!this.props.isLoading && <NextPageButton/>}
                {this.props.isLoading && <Loader/>}
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    { 
        getTransactions, 
        getMerchant, 
        setTransactionData,
        setMerchantUser, 
        logOut,
        setError 
    }
)(TransactionsPage);
