import React from 'react';
import classNames from "classnames";

import logo from '@wisetack/assets/img/wisetack-logo.svg';

import styles from './NavBar.module.scss';

import prequal from "../assets/img/prequal_history.svg";
import transactions from "../assets/img/transaction_history.svg";
import calculator from "../assets/img/calculator.svg";
import faq from "../assets/img/faq.svg";
import help from "../assets/img/help.svg";
import logout from "../assets/img/logout.svg";
import settings from "../assets/img/menu_settings.svg";
import resources from "../assets/img/resources.svg";
import user from "../assets/img/user.svg";
import arrow from "../assets/img/back_arrow.svg";


function NavBar({title, users, onMenuItemClick, onBackArrowClick}) {

    const MenuItem = ({capture, alias, icon}) => {
        return (
            <div className={styles.item} onClick={() => onMenuItemClick({alias})}>
                <div className={styles.icon}>
                    <img src={icon} alt={alias}/>
                </div>
                {capture}
            </div>
        )
    }

    const AccountItem = ({item, clickable}) => {


        const accountClasses = classNames({
            [styles.account]: true,
            [styles.clickable]: clickable,
        });

        return (
            <div className={accountClasses} onClick={() => clickable && onMenuItemClick({alias: "account", data: item})}>
                <div className={styles.merchant}>
                    {item.merchantName}
                </div>
            </div>
        )
    }

    const AccountList = () => {
        if (!users || users.length === 0) {
            return null;
        }

        if (users.length === 1) {
            return <>
                <AccountItem item={users[0]}/>
                <div className={styles.divider}></div>
            </>
        }

        const cardHeaderClasses = classNames({
            "card-header": true,
            [styles.cardHeader]: true
        });

        const cardBodyClasses = classNames({
            "collapse": true,
            [styles.cardBody]: true
        });

        return (
            <div className="card card-collapse">
                <div className={cardHeaderClasses}>
                    <a
                        data-toggle="collapse"
                        href="#collapseAccounts"
                    >
                        <div className={styles.accountHeader}>
                            <div className={styles.icon}>
                                <img src={user} alt={"account"}/>
                            </div>
                            <AccountItem item={users[0]}/>
                            <div className={styles.arrow}>
                                <i className="material-icons">keyboard_arrow_down</i>
                            </div>
                        </div>
                    </a>
                </div>
                <div id="collapseAccounts" className={cardBodyClasses}>
                    {users.slice(1).map(user => {
                        return (
                            <AccountItem item={user} key={user.userId} clickable={true}/>
                        )
                    })}
                </div>
                <div className={styles.divider}></div>
            </div>
        )
    }

    const Title = () => {
        if (title) {
            return <div className={classNames("col", styles.title)}>{title}</div>
        }
        return <img className={styles.logo} src={logo} alt="wisetack-logo" />
    }

    return (
        <div className={classNames("row", styles.navbar)}>
            <div className="col">
                {onBackArrowClick && <img className={styles.back_arrow} src={arrow} alt={"arrow.svg"} onClick={() => onBackArrowClick()}/>}
            </div>
            <Title/>
            <div className="col">
                <div id="menuToggle" className={styles.hamburger}>
                    <input type="checkbox" defaultChecked={false}/>
                    <span className={styles.hmbrg}></span>
                    <span className={styles.hmbrg}></span>
                    <span className={styles.hmbrg}></span>
                    <div className={styles.menu}>
                        <AccountList />
                        <MenuItem capture="Prequalifications" alias="prequal_history" icon={prequal}/>
                        <MenuItem capture="Transaction History" alias="transaction_history" icon={transactions}/>
                        <MenuItem capture="Payment Calculator" alias="calculator" icon={calculator}/>
                        <div className={styles.divider}></div>
                        <MenuItem capture="Resources" alias="resources" icon={resources}/>
                        <MenuItem capture="FAQ" alias="faq" icon={faq}/>
                        <MenuItem capture="Help" alias="help" icon={help}/>
                        <div className={styles.divider}></div>
                        <MenuItem capture="Account Settings" alias="account_settings" icon={settings}/>
                        <MenuItem capture="Log out" alias="log_out" icon={logout}/>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NavBar;


