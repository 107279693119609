import React from "react";
import styles from "./JobIdInfoModal.module.scss";
import classNames from "classnames";
import image from "@wisetack/assets/img/info-icon.svg";

const JobIdInfoModal = ({messages}) => {
    return (
        <div
            className={classNames("modal fade", styles.modal)}
            id="jobIdInfoModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby={`jobIdInfoLabel`}
            aria-hidden="false"
        >
            <div
                className={classNames("modal-dialog", styles.modalDialog)}
                role="document"
            >
                <div className={classNames("modal-content", styles.modalContent)}>
                    <div className="modal-header">
                        <button
                            type="button"
                            className="close"
                            data-test-id="close-jobIdInfoModal"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
              <span className={styles.close} aria-hidden="true">
                &times;
              </span>
                        </button>
                    </div>
                    <div className={classNames("modal-body", styles.modalBody)}>
                        <img className={styles.logo} src={image} alt="bank-logo"/>
                        <h4>
                            {messages['modal.job_id.header']}
                        </h4>
                        <div>
                            {messages['modal.learn_more.text.p1']}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobIdInfoModal;
