import React from "react"
import classNames from "classnames";
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import {
    setError,
    applyFilter
} from "../store/actions/merchantActions";
import { countSelected } from "../utils/format"
import { loadDynamicUserFilter } from "../store/actions/merchantActions";

import styles from "./FiltersPage.module.scss";

const stateNames = [
    "isLoading",
    "errorMessage",
    "user",
    "filter",
    "merchantUsers"
]

const pageName = "Transaction Filters Page"
const logProps = {page: pageName}

const getSelectedFilterIds = (filterGroup) => {
    return Object.keys(filterGroup)
        .filter(filterId => filterGroup[filterId].selected)
}

const getSelectedItems = (filter) => {
    return Object.keys(filter)
        .flatMap(group => getSelectedFilterIds(filter[group]))
        .reduce((acc, key) => {return {...acc, [key]: true}}, {})
}

class FiltersPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            errorMessage: "",
            filter: {...this.props.filter},
            currentSelectedItems: getSelectedItems(this.props.filter),
            originalSelectedItems: getSelectedItems(this.props.filter),
            dropdownOpen: {},
        }
        if(!this.props.filter.users)
            this.state.filter.users = {}
        
    }

    merchantId = null;
    componentDidMount() {
        if (!sessionStorage.getItem("wisetack:ba:token")) {
            Log.error('Token not found.')
            this.props.history.push("/");
            return;
        }
        if (!this.props.user) {
            Log.error('User not found.')
            // this.props.history.push("/");
            // return;
        }

        if (this.props.merchant) {
            this.merchantId = this.props.merchant.id;
        } else {
            this.merchantId = localStorage.getItem("merchant:id");
        }
        if (Object.keys(this.state.filter.users).length === 0)
            this.props.loadDynamicUserFilter(this.merchantId);

        window.scrollTo(0, 0);
        logProps.merchantId = this.props.user ? this.props.user.merchantId : null;
        logAmplitudeEvent(pageName, logProps);
    }

    

    componentDidUpdate(prevProps) {

        if(prevProps.filter !== this.props.filter){      
            this.setState((state) => ({...state.filter.users = this.props.filter.users}));
        }
    }

    onClose = () => {
        this.props.history.push("/transactions");
    }

    onApply = () => {
        this.props.applyFilter(this.state.filter)
        this.props.history.push("/transactions");
    }

    countFilterSelections = () => {
        return countSelected(this.state.filter, Object.keys(this.state.filter))
    }
    
    resetAllFilters = () => {

        const filter = JSON.parse(JSON.stringify(this.state.filter)); // deep clone

        Object.values(filter).forEach(group => {
            Object.entries(group).forEach(([filterId, filter]) => {
                filter.selected = false;
            })
        })

        this.setState({filter: filter})

        const currentSelectedItems = {...this.state.currentSelectedItems};
        Object.keys(currentSelectedItems).forEach(filterId => {
            currentSelectedItems[filterId] = false
        })

        this.setState({currentSelectedItems: currentSelectedItems})
    }

    onDropdownSelectClick = (id) => {
        const currentDropDownOpen = this.state.dropdownOpen;
        const isOpen = !! currentDropDownOpen[id];
        currentDropDownOpen[id] = !isOpen;

        this.setState({dropdownOpen:currentDropDownOpen})
    }

    setCurrentFilterSelection = (filterId, selected) => {
        this.setState({
            currentSelectedItems: {
                ...this.state.currentSelectedItems,
                [filterId]: selected
            }
        })
    }

    render() {
        Log.info({ state: this.state, props: this.props }, `FiltersPage state/props`);
        const filterSelections = this.countFilterSelections()

        const HeaderContent = () => {
            return (
                <div className={classNames("row", styles.header)}>
                    <div className={"col"} style={{maxHeight: "20px"}}>
                        <div className={classNames("material-icons", styles.close)} onClick={this.onClose}>close</div>
                    </div>
                    <div className={"col"} style={{maxHeight: "20px"}}>
                        <div className={styles.title}>Filters</div>
                    </div>
                    <div className={"col"} style={{maxHeight: "20px"}}>
                        <div className={styles.reset}>
                            {filterSelections > 0 &&
                                <span className="material-icons" onClick={() => this.resetAllFilters()}>refresh</span>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        const SelectItem = ({groupId, itemId, item, onClick}) => {
            let icon
            if (item.selected) {
                icon = "check_box"
            } else {
                icon = "check_box_outline_blank"
            }
            let text = item.description ? item.description : item.text
            return (
                <div className={classNames("row", styles.selectItem)} onClick={() => onClick(groupId, itemId, item)} >
                    <div className={classNames("col", styles.box)}>
                        <span className={classNames({"material-icons-outlined": true, [styles.selected]: item.selected})}>
                            {icon}
                        </span>
                    </div>
                    <div className={classNames("col", styles.text)}>{text}</div>
                </div>
            )
        }
        const handleSelectItemClick = (groupId, itemId, item) => {

            const selected = !item.selected
            const filter = JSON.parse(JSON.stringify(this.state.filter)); // deep clone
            filter[groupId][itemId].selected = selected
            this.setState({filter: filter})

            this.setCurrentFilterSelection(itemId, selected)
        }

        const DropdownMultiSelect = ({id, caption, filters}) => {
            const isDropDownOpened = !! this.state.dropdownOpen[id];

            let value = ""

            Object.values(filters).forEach(item => {
                if (item.selected) {
                    if (value) {
                        value = value + ", "
                    }
                    value = value + item.text
                }
            })
            if (!value) {
                value = "All " + id
            }

            const arrowClasses = classNames({
                "material-icons": true,
                [styles.expanded]: isDropDownOpened
            });

            return (
                <div className={styles.filter}>
                    <div className={styles.label}>
                        {caption}
                    </div>
                    <div className={styles.form}
                         onClick={() => this.onDropdownSelectClick(id)}>
                        <div className={styles.title}>
                            {value}
                        </div>
                        <div className={styles.toggle}>
                        <span>
                            <i className={arrowClasses}>
                                   keyboard_arrow_down
                            </i>
                        </span>
                        </div>
                    </div>
                    {isDropDownOpened &&
                    <div className={styles.options}>
                        {Object.entries(filters).map( ([key, value]) => {
                            return (
                                <SelectItem key={key} groupId={id} itemId={key} item={value} onClick={handleSelectItemClick}/>
                            )
                        })}
                    </div>
                    }
                </div>
            )
        }

        const isApplyButtonEnabled = () => {

            for ( const [filterId, selected] of Object.entries(this.state.currentSelectedItems)) {
                const currentSelected = selected;
                const originalSelected = !!this.state.originalSelectedItems[filterId];
                if (currentSelected !== originalSelected) {
                    return true;
                }
            }

            return false;
        }

        const ApplyButton = () => {

            let btnEnabled = isApplyButtonEnabled();

            const btnClasses = classNames({
                btn: true,
                "btn-block": true,
                [styles.buttonDisabled]: !btnEnabled,
                [styles.buttonEnabled]: btnEnabled
            });

            const onClick = btnEnabled ? this.onApply : () => {return false};

            let caption = "APPLY"
            if (filterSelections > 0) {
                caption = caption + ` (${filterSelections})`
            }

            return (
                <div className="row">
                    <div className="col">
                        <button className={classNames(btnClasses)} onClick={onClick}>
                            {caption}
                        </button>
                    </div>
                </div>
            )
        }

        return (
            <Container>
                <HeaderContent/>
                <LoaderWithMessage loading={this.props.isLoading} />
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                {!this.props.isLoading &&
                    <>
                        <div className={styles.filters}>
                            <DropdownMultiSelect id="statuses" caption="Loan status" filters={this.state.filter.statuses}/>
                            <DropdownMultiSelect id="years" caption="Year" filters={this.state.filter.years}/>
                            <DropdownMultiSelect id="months" caption="Month" filters={this.state.filter.months}/>
                            <DropdownMultiSelect id="users" caption="User" filters={this.state.filter.users}/>
                        </div>
                        <ApplyButton/>
                    </>
                }
            </Container >
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        loadDynamicUserFilter,
        setError,
        applyFilter
    }
)(FiltersPage);
