import React from "react";
import {connect} from "react-redux";
import {useHistory} from "react-router";
import classNames from "classnames";

import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import useApiRequest from "@wisetack/shared-ui/src/utils/useApiRequest";
import image from "@wisetack/assets/img/paper-plane.svg";

import Container from "../components/Container";
import NavBar from "../components/NavBar";
import useAppFlow from "../hooks/useAppFlow";
import {getMerchant} from "../store/actions/merchantActions";
import styles from "./PrequalSentPage.module.scss";

const pageName = 'Prequal Sent Page'

function PrequalSentPage({getMerchant}) {
    const history = useHistory();

    const [requestId, requestInProgress, requestError, newRequest] = useApiRequest('merchant');
    const {users, handleMenuItemClick, logAmplitude} = useAppFlow({getMerchant, newRequest}, pageName)
    const loading = !!requestId && requestInProgress

    const btnClasses = classNames({
        btn: true,
        "btn-block": true,
        [styles.buttonEnabled]: true
    });

    return <Container>
        <NavBar title={"New prequalification"} users={users} onMenuItemClick={handleMenuItemClick}/>
        <div className={styles.subtitle}>Application sent</div>
        <LoaderWithMessage loading={loading}/>
        { !loading && <>
            <img className={styles.image} src={image} alt="sent-logo" />
            <div className={styles.message}>
                We will notify you of any application updates. You can also check for status updates under Prequal Applications.
            </div>
            <Error>{requestError}</Error>
            <div
                className="row"
                style={{ paddingTop: "15px" }}
            >
                <div className="col">
                    <button
                        data-test-id="continue"
                        className={btnClasses}
                        onClick={() => {
                            logAmplitude('Done button clicked')
                            history.push("/prequals");
                        }}
                    >
                        DONE
                    </button>
                </div>
            </div>
        </>
        }
    </Container>
}

export default connect(null, {getMerchant})(
    PrequalSentPage
);

