import React from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";
import ownStyles from "./IdInformation.module.scss";

export function IdInformation({ item, messages }) {
    const loanId = item.id || ""
    const jobId = item.jobId

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    href="#collapseIdInformation"
                >
                    <div className={styles.header}>
                        ID Information
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseIdInformation" className={cardBodyClasses}>
                <div className={classNames(ownStyles.infoWrapper)}>
                    <div className={classNames(ownStyles.info, styles.info)}>
                        <span className="material-icons-outlined">info</span>
                    </div>
                    <div className={classNames(ownStyles.job, styles.info)}>{messages['id_information.job_id']}:&nbsp;{jobId}</div>
                    <div className={classNames(ownStyles.loan, styles.info)}>Loan&nbsp;ID:&nbsp;{loanId}</div>
                </div>
            </div>
        </div>
    )
}
