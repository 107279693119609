import { customizeErrorMessage } from "./format"
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import {formatUsPhone} from "@wisetack/shared-ui/utils/format";

export const stateFromProps = (nextProps, prevState, logProps) => {
    let newState = {}
    if (nextProps.user && nextProps.users) {
        if (!prevState.userId || prevState.userId !== nextProps.user.userId) {
            const users = [];
            const userId = nextProps.user.userId;
            logProps.userId = userId;
            logProps.merchantId = nextProps.user.merchantId;
            nextProps.users.forEach(item => {
                item.selected = item.userId === userId;
                if (item.selected) {
                    users.unshift(item);
                } else {
                    users.push(item);
                }
            })
            newState.users = users;
        }
    }
    if (nextProps.prequal && nextProps.location.pathname === '/send_transaction') {
        if (!prevState.mobileNumber && nextProps.prequal.customerInformation && nextProps.prequal.customerInformation.phone) {
            newState.mobileNumber = formatUsPhone(nextProps.prequal.customerInformation.phone.replace('+1',''))
        }
        if (!prevState.transactionPurpose && nextProps.prequal.transactionPurpose) {
            newState.transactionPurpose = nextProps.prequal.transactionPurpose
        }
        if (!prevState.errors) {
            // to prevent issue with enabled submit button on new transaction page load
            newState.errors = {'transactionAmount': true}
        }
    }
    let errorMessage = nextProps.errorMessage
    if (errorMessage) {
        if (prevState.rawError !== errorMessage) {
            newState.rawError = errorMessage;
            const fieldsError = customizeErrorMessage(errorMessage)
            if (fieldsError) {
                logAmplitudeEvent("Fields error", {
                    ...logProps,
                    fieldsError
                });
                if (fieldsError.token === 'The Token has expired') {
                    if (nextProps.location.pathname !== '/') {
                        nextProps.history.push("/");
                        return;
                    }
                }
                newState.fieldsError = fieldsError
            } else {
                logAmplitudeEvent("Error message", {
                    ...logProps,
                    errorMessage
                });
                newState.errorMessage = errorMessage 
            }
        }            
    } else {
        if(prevState.fieldsError) {
            newState.fieldsError = null
        }
        if(prevState.errorMessage) {
            newState.errorMessage = null
        }
    }
    return newState;
}
