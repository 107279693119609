import React from "react";

function ModalWrapper ({children, showModal, modalId}) {
    return (
        <div data-toggle="modal" data-target={showModal ? modalId : ''}>
            {children}
        </div>
    )
}

export default ModalWrapper;