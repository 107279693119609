import styles from "./TransactionsControl.module.scss";
import filtering_svg from "../assets/img/settings.svg";
import new_transaction from "../assets/img/add_circle.svg";
import React from "react";
import {isNewTransactionAvailable} from "@wisetack/shared-ui/utils/transactions";
import TransactionExport from "./TransactionExport";

class TransactionsControl extends React.Component {

    render() {
        return (
            <div className={styles.controls}>
                <div className={styles.filter} onClick={this.props.handleFilterClick}>
                    <img className={styles.filter_img} src={filtering_svg} alt="filtering"/>
                    {this.props.selectedFiltersCount > 0 && <span>({this.props.selectedFiltersCount})</span>}
                </div>
                { isNewTransactionAvailable(this.props.user) &&
                    <div className={styles.create} onClick={this.props.handleCreateClick}>
                        <img data-test-id="new_transaction" className={styles.create_img} src={new_transaction} alt="new_transaction"/>
                        <span className={styles.label}>NEW</span>
                    </div>
                }
                <TransactionExport trxType={this.props.trxType}/>
            </div>
        );
    }
}

export default TransactionsControl;
