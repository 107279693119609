import React from "react";
import classNames from "classnames";

import styles from "./FiltersHeader.module.scss";

const FiltersHeader = ({onClose, onReset}) => {
    return (
        <div className={classNames("row", styles.header)}>
            <div className={"col"} style={{maxHeight: "20px"}}>
                <div className={classNames("material-icons", styles.close)} onClick={onClose}>close</div>
            </div>
            <div className={"col"} style={{maxHeight: "20px"}}>
                <div className={styles.title}>Filters</div>
            </div>
            <div className={"col"} style={{maxHeight: "20px"}}>
                <div className={styles.reset}>
                    {!!onReset &&
                    <span className="material-icons" onClick={() => onReset()}>refresh</span>
                    }
                </div>
            </div>
        </div>
    )
}

export default FiltersHeader;