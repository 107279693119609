import React from "react";
import classNames from "classnames";
import { formatUsPhoneToDomestic } from "@wisetack/shared-ui/utils/format";
import { formatBorrowerAddress } from "../utils/format";
import styles from "./PrequalCustomerInformation.module.scss";

export function PrequalCustomerInformation({ consumer = {} }) {
    const name = consumer.shortName || ''
    const phone = formatUsPhoneToDomestic(consumer.phone || '', true)
    const address = formatBorrowerAddress(consumer)

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse show": true,
        [styles.cardBody]: true
    });

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    aria-expanded="true"
                    href="#collapseCustomer"
                >
                    <div className={styles.header}>
                        Customer information
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseCustomer" className={cardBodyClasses}>
                <div className={styles.info}>
                    <span className="material-icons-outlined">account_circle</span>
                    {name}
                </div>
                <div className={styles.info}>
                    <span className="material-icons-outlined">phone</span>
                    {phone}
                </div>
                <div className={styles.info}>
                    <span className="material-icons-outlined">home</span>
                    {address}
                </div>
            </div>
        </div>
    )
}
