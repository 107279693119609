import React from "react";

function ButtonContainer({ children }) {
    if(!children) {
        return null;
    }
    if (!Array.isArray(children)) {
        children = [children];
    }
    return (
        <div
            className="row"
            style={{ marginTop: "5px", marginBottom: "5px" }}
        >
            {
                children.map((item, index) => {
                    if(!item.props) {
                        return null;
                    }
                    return (
                        <div className="col" key={index}>
                            {item}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ButtonContainer;
