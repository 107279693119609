import React from "react";
import classNames from "classnames";
import styles from "./CustomerInformation.module.scss";
import ownStyles from "./LoanStatusHistory.module.scss";
import moment from "moment";

export function LoanStatusHistory({ item }) {
    const statusHistory = item.statusHistory;

    const cardHeaderClasses = classNames({
        "card-header": true,
        [styles.cardHeader]: true
    });

    const cardBodyClasses = classNames({
        "collapse": true,
        [styles.cardBody]: true
    });

    const formatDate = (dateStr) => {
        if(!dateStr || dateStr.length < 10) {
            return '- -'
        }
        dateStr = dateStr.substring(0, 10)
        return moment(dateStr,"YYYY-MM-DD").format("MMM DD, YYYY")
    }

    const iconStatusStyle = {
        paddingRight: 0,
        width: "1%"
    };

    const tdToRight = {
        textAlign: "right",
        paddingRight: "0px"
    };

    return (
        <div className="card card-collapse">
            <div className={cardHeaderClasses}>
                <a
                    data-toggle="collapse"
                    href="#collapseLoanStatusHistory"
                >
                    <div className={styles.header}>
                        Loan status
                        <span>
                            <i className="material-icons">keyboard_arrow_down</i>
                        </span>
                    </div>
                </a>
            </div>
            <div id="collapseLoanStatusHistory" className={cardBodyClasses}>
                <div className={classNames(ownStyles.status, styles.info)}>
                    <table width="100%">
                        <tbody>
                        {statusHistory && statusHistory.map((statusEntry, index) => {
                            return (
                                <tr key={statusEntry.status + "#" + statusEntry.date}
                                    className={classNames(ownStyles.status, styles.info)}>
                                    <td style={iconStatusStyle}>
                                        {index === 0 && <span className="material-icons-outlined">history</span>}
                                    </td>
                                    <td>
                                        {statusEntry.status}
                                    </td>
                                    <td style = {tdToRight}>
                                        {formatDate(statusEntry.date)}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
