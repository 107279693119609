import React from "react";
import Button from "./Button";
import styles from "./ButtonOutlined.module.scss"

function ButtonOutlined({ children, disabled, onClick}) {

    const outlinedButtonClasses = {
        [styles.enabled]: !disabled
    };

    return (
        <Button customClasses={outlinedButtonClasses} disabled={disabled} onClick={onClick}>
            {children}
        </Button>
    )
}

export default ButtonOutlined;
