import {redirectToEntryPage} from "./transitions";

export const verifyWisetackToken = (props) => {
    if (!sessionStorage.getItem("wisetack:ba:token")) {
        redirectToEntryPage(props, 'Token not found')
    }
}

export const isAdmin = (user) => {
    if (!user){
        return false
    }
    return user.userRole === 'ADMIN'
}
