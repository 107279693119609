import React from "react"
import classNames from "classnames";
import { connect } from "react-redux";

import Log from "@wisetack/shared-ui/utils/Log";
import LoaderWithMessage from "@wisetack/shared-ui/components/LoaderWithMessage";
import Error from "@wisetack/shared-ui/components/Error";
import Container from "../components/Container";
import { logAmplitudeEvent } from "@wisetack/shared-ui/components/Amplitude";
import image from "@wisetack/assets/img/paper-plane.svg";

import {
    getMerchant,
    setMerchantUser,
    logOut,
    setError
} from "../store/actions/merchantActions";
import { appTransition } from "../utils/transitions"

import styles from "./TransactionReviewPage.module.scss";
import { stateFromProps } from "../utils/state";
import NavBar from "../components/NavBar";

const stateNames = [
    "isLoading",
    "errorMessage",
    "merchant",
    "users",
    "user",
    "newTransaction"
]

const pageName = "Transaction Review Page"
const logProps = {page: pageName}

class TransactionReviewPage extends React.Component {
    state = {
        errorMessage: "",
        userChanged: false,
        userId: null
    }

    componentDidMount() {
        if (!sessionStorage.getItem("wisetack:ba:token")) {
            Log.error('Token not found.')
            this.props.history.push("/");
            return;
        }
        if (!this.props.newTransaction) {
            Log.error('Transaction not found.')
            this.props.history.push("/new_transaction");
            return;
        }
        window.scrollTo(0, 0);
        let merchantId = null;
        if (this.props.merchant) {
            merchantId = this.props.merchant.id;
        } else {
            merchantId = localStorage.getItem("merchant:id");
            if (merchantId) {
                this.props.getMerchant(merchantId);
            }
        }
        if (!merchantId) {
            Log.error('Merchant ID not found.')
            this.props.history.push("/");
            return;
        }
        logProps.merchantId = merchantId
        logAmplitudeEvent(pageName, logProps);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return stateFromProps(nextProps, prevState, logProps)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user && this.props.user && prevProps.user.merchantId !== this.props.user.merchantId) {
            // TODO user changed
        }
    }

    handleMenuItemClick = (e) => {
        logAmplitudeEvent("Menu item clicked", {
            ...logProps,
            menuItem: e.alias 
        });
        this.props.setError(null)
        appTransition(e, this.props)
    }

    formatPhone = (phone) => {
        let phoneNumber = phone;
        if (!phoneNumber) return "";
        if (phoneNumber[0] === "+") phoneNumber = phoneNumber.substr(2);
        return (
            phoneNumber.substr(0, 3) +
            "-" +
            phoneNumber.substr(3, 3) +
            "-" +
            phoneNumber.substr(6)
        );
    };

    render() {
        Log.info({ state: this.state, props: this.props }, `TransactionReviewPage state/props`);
        const transaction = this.props.newTransaction

        const HeaderContent = () => {
            if (!transaction) {
                return null
            }
            return (
                <>
                    <NavBar title={"New transaction"}
                            users={this.state.users}
                            onMenuItemClick={this.handleMenuItemClick}
                    />
                    <img className={styles.image} src={image} alt="bank-logo" />
                </>
            )
        }

        const btnClasses = classNames({
            btn: true,
            "btn-block": true,
            [styles.button]: true
        });

        return (
            <Container>
                <HeaderContent />
                {transaction &&
                <div className={styles.description}>
                    We will notify you of any application updates. You can also check for status updates under Transactions.
                </div>
                }
                <Error pageName={pageName}>{this.state.errorMessage}</Error>
                <button className={btnClasses} onClick={() => {
                    logAmplitudeEvent("View All Transactions button clicked", logProps);
                    this.props.history.push("/transactions")
                }}>
                    BACK TO TRANSACTIONS
                </button>
                <LoaderWithMessage loading={this.props.isLoading} />
            </Container>
        )
    }
}

const setPropFromState = (props, state, name, path) => {
    if (path) {
        state = state.merchant[path];
    } else {
        state = state.merchant
    }
    if (state) {
        props[name] = state[name];
    }
};

const mapStateToProps = (state) => {
    let props = {
        fieldsError: {},
        fieldsValue: {}
    };
    stateNames.forEach(name => setPropFromState(props, state, name));
    return props;
};

export default connect(
    mapStateToProps,
    {
        getMerchant,
        setMerchantUser,
        logOut,
        setError
    }
)(TransactionReviewPage);
